import OverlayImage from "../images/Rectangle -1.webp";
import Arrow from "../images/icons/arrow-10.png";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';



import TVCVideo from '../showrealsservices/TVC 20 S V-4.mp4';
import VIDEOCLIPSVideo from '../showrealsservices/Video Clip 30s V-4.mp4';
import DOCUMANTARIESVideo from '../showrealsservices/Documentaries 30 S V-03.mp4';
import TELLYOURSTORYVideo from '../showrealsservices/Tell your Story 30 S V-4.mp4';
import ANIMATIONVideo from '../showrealsservices/20 S 2D - 3D Animation- V- 3.mp4';
import TVSERVICESVideo from '../showrealsservices/TV Services V1.mp4';
import EVENTHIGHLIGHTSVideo from '../showrealsservices/Event Highlights- 30 S V-3.mp4';
import BTSVideo from '../showrealsservices/BTS 20 S V-3.mp4';
import AudioVideo from '../showrealsservices/auditory.mp4';
import StorageVideo from '../showrealsservices/storage.mp4';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function WorkPage(){


    const ProjectsData = [
        {
        id: '1',
        category: 'tvc',
        catText : ' الإنتاج الإعلاني ',
        catdesc : 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
        catshowreal  : TVCVideo,
        header: ' الممر العالمي للذكاء الاصطناعي ',
        description: [
            " سافرنا في رحلة شيقة، عبر ممر الذكاء الاصطناعي، لنضم ثلاثة مطورين من مواقع مختلفة بهدف مشترك. يتعاونون في مشروع ذكاء اصطناعي، ويشاركون مهاراتهم وخبراتهم. "
        ],
        vimeolink: [
            "https://player.vimeo.com/video/893021260?h=d911aef641&title=0&byline=0&portrait=0" ,
        ]
        },

        {
            id: '2',
            category: 'tvc',
            catText : ' الإنتاج الإعلاني ',
        catdesc : 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
        catshowreal  : TVCVideo,
            header: 'لاب ٧ ',
            description: [
                " كتبنا وأنتجنا الفيديو الإعلاني لـ LAB7  غرفة خالد تتسع لملايين من الأفكار المميزة، لكن ما كان يعرف المكان الصحيح لتحقيقها.  حتى سجل في LAB7."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898878950?h=c1c39537b9&title=0&byline=0&portrait=0",
            ]
        },

        {
            id: '3',
            category: 'tvc',
        catText : ' الإنتاج الإعلاني ',
        catdesc : 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
        catshowreal  : TVCVideo,
            header: ' التحالف الإسلامي العسكري لمحاربة الإرهاب - فضول خطير            ',
            description: [
                " فيصل طالب في المرحلة المتوسطة، يقوده فضوله في الإنترنت إلى مكان خطير لم يحسب لعواقبه حساب.ن حملة اصنع الفرق التي كتبنا وأنتجنا سلسلتها وتم اطلاقها مع التحالف الإسلامي العسكري لمحاربة الإرهاب. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898886244?h=ae58db69df&title=0&byline=0&portrait=0" ,
            ]
        },

        
        {
            id: '4',
            category: 'tvc',
        catText : ' الإنتاج الإعلاني ',
        catdesc : 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
        catshowreal  : TVCVideo,
            header: ' التحالف الإسلامي العسكري لمحاربة الإرهاب - انتحال الهوية ',
            description: [
                " محمد شاب يقع ضحية عملية احتيال لسرقة هويته، نتتبع رحلته وهو يصارع لإعادة الأمور لمجراها، لكن بعض التحديات تعترض طريقه. من حملة اصنع الفرق التي كتبنا وأنتجنا سلسلتها وتم اطلاقها مع التحالف الإسلامي العسكري لمحاربة الإرهاب.  "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898883246?h=98883dc8c2&title=0&byline=0&portrait=0" ,
            ]
        },


        
        {
            id: '5',
            category: 'tvc',
        catText : ' الإنتاج الإعلاني ',
        catdesc : 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
        catshowreal  : TVCVideo,
            header: ' فورملا ون المدارس',
            description: [
                " كتبنا وأنتجنا هذا العمل الخاص ببرنامج فورمولا 1 في المدارس المعني بتمكين الشباب في مجالات العلوم والتكنولوجيا والهندسة والرياضيات في المستقبل. نتابع قصة طلاب يخوضون المراحل المختلفة لصناعة سيارة الفورمولا F1."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893019085?h=57a2ab7ba9&title=0&byline=0&portrait=0" ,
            ]
        },


        
        
        {
            id: '6',
            category: 'tvc',
        catText : ' الإنتاج الإعلاني ',
        catdesc : 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
        catshowreal  : TVCVideo,
            header: '  المؤسسة العامة لتحلية المياه المالحة اليوم الوطني السعودي ٩٠ ',
            description: [
                "في اليوم الوطني نستعرض النجاح المذهل لمؤسسة تحلية المياه خلال تحديات كوفيد ١٩، تمكنت التحلية على تجاوز التوقعات، مما يجعلها نموذجًا يحتذى به في جميع أنحاء العالم."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/462131550?h=93a97494af&title=0&byline=0&portrait=0" ,
            ]
        },

                
        {
            id: '7',
            category: 'tvc',
        catText : ' الإنتاج الإعلاني ',
        catdesc : 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
        catshowreal  : TVCVideo,
            header: '  أرامكو تشجير منتزه الأحساء',
            description: [
                "لطالما تميزت محافظة الأحساء بتنوع جغرافيتها الطبيعية التي تحتوي على واحة زراعية، وثقنا مع أرامكو جهودها المتواصلة في زراعة أشجار محلية في منتزه الأحساء الوطني في تنمية الغطاء النباتي ومكافحة التصحر."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/896846160?h=8cd46a0d25&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '8',
            category: 'tvc',
        catText : ' الإنتاج الإعلاني ',
        catdesc : 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
        catshowreal  : TVCVideo,
            header: ' متحف إثراء  ',
            description: [
                "من منطلق مقولة  الفن هو ما يراه المتلقي  أنتجنا هذا العمل الرائع لنذهب في رحلة الفن والثقافة من قلب متحف إثراء  وعبر الحديث مع المختصين."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898877123?h=ff060969dd&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '9',
            category: 'tvc',
        catText : ' الإنتاج الإعلاني ',
        catdesc : 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
        catshowreal  : TVCVideo,
            header: ' الممر العالمي للذكاء الاصطناعي  ',
            description: [
                "سافرنا في رحلة شيقة، عبر ممر الذكاء الاصطناعي، لنضم ثلاثة مطورين من مواقع مختلفة بهدف مشترك. يتعاونون في مشروع ذكاء اصطناعي، ويشاركون مهاراتهم وخبراتهم. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893021260?h=d911aef641&title=0&byline=0&portrait=0" ,
            ]
        },

        
        {
            id: '9',
            category: 'tvc',
        catText : ' الإنتاج الإعلاني ',
        catdesc : 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
        catshowreal  : TVCVideo,
            header: ' فيديو خريجين أكاديمية التميز  ',
            description: [
                "اعتلينا المنصة مع خريجي الدفعة الأولى والثانية في برامج أكاديمية التميز لنوثق تجاربهم المتنوعة في الأكاديمية التي يخرج منها قادة المستقبل."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/896846643?h=13151de7cc&title=0&byline=0&portrait=0" ,
            ]
        },

                
        {
            id: '10',
            category: 'tvc',
        catText : ' الإنتاج الإعلاني ',
        catdesc : 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
        catshowreal  : TVCVideo,
            header: ' مسك للمجتمع - حاضنة مسك  للمبادرات',
            description: [
                "قصة جديدة مع عميل مميز، أطلقنا برنامج حاضنة مسك للمبادرات في الدفعة الثالثة، ويعتبر البرنامج فرصة لتطوير ونجاح مبادرتك."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898071342?h=c59bd1befe&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '11',
            category: 'tvc',
        catText : ' الإنتاج الإعلاني ',
        catdesc : 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
        catshowreal  : TVCVideo,
            header: ' أبطالنا أبطال الوطن',
            description: [
                "نفخر في هذا العمل الوطني بالتعاون مع إثراء من خلاله أكدنا بأن أبطالنا أثبتوا أنفسهم بأفعالهم التي قدموها لوطننا في مختلف المجالات والمهام. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898356632?h=e988e541c4&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '12',
            category: 'tvc',
        catText : ' الإنتاج الإعلاني ',
        catdesc : 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
        catshowreal  : TVCVideo,
            header: 'الإتزان الرقمي',
            description: [
                "ماذا تعرف عن الاتزان الرقمي؟ استمعنا إلى مشاركة الخبراء في برنامج الاتزان الرقمي في إثراء في رحلة مليئة بالمعلومات العلمية المفيدة. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898347551?h=e16c517c25&title=0&byline=0&portrait=0" ,
            ]
        },


        // {
        //     id: '13',
        //     category: 'videoclips',
        //     catText : ' صناعة الفيديو كليب',
        // catdesc : ' نترجم مشاعركم إلى نغمات كلمـــــات ولقطـــات تلامس القلــــوب عبر عـــن رسالتكـم بأسلـــوب ملفــــــت.',
        // catshowreal  : VIDEOCLIPSVideo,
        //     header: 'أرامكو - أغنية نادي القادسية السعودي ||',
        //     description: [
        //         "تشرفنا بالعمل مع أرامكو في أول مشاريعها بعد ما تم نقل ملكية نادي القادسية السعودي لها، لحنا كلماتنا وتغنينا بها وانتجنا فيديو كليب تحت عنوان قدساوي أول وتالي."
        //     ],
        //     vimeolink: [
        //         '',
        //     ]
        // },

        {
            id: '14',
            category: 'videoclips',
            catText : ' صناعة الفيديو كليب',
        catdesc : ' نترجم مشاعركم إلى نغمات كلمـــــات ولقطـــات تلامس القلــــوب عبر عـــن رسالتكـم بأسلـــوب ملفــــــت.',
        catshowreal  : VIDEOCLIPSVideo,
            header: ' إثراء عيد الفطر  ',
            description: [
                "كتبنا وأنتجنا أغنية عيد الفطر لإثراء لعام 2020 وكانت رسالتنا في هذا العمل بأن مهما شاءت الظروف لا تتأثر فرحتنا بقدوم العيد."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898349091?h=6d2440f60b&title=0&byline=0&portrait=0" ,
            ]
        },

        
        // {
        //     id: '15',
        //     category: 'videoclips',
        //     catText : ' صناعة الفيديو كليب',
        // catdesc : ' نترجم مشاعركم إلى نغمات كلمـــــات ولقطـــات تلامس القلــــوب عبر عـــن رسالتكـم بأسلـــوب ملفــــــت.',
        // catshowreal  : VIDEOCLIPSVideo,
        //     header: ' أرامكو عيد الأضحى ٢٠٢١',
        //     description: [
        //         "شاركنا أرامكو فرحة عيد الأضحى، وكتبنا وأنتجنا هذا العمل لنوضح مفهوم " العيد بشوفة الأهل والأحباب عيدين"
        //     ],
        //     vimeolink: [
        //         '',
        //     ]
        // },


        {
            id: '16',
            category: 'videoclips',
            catText : ' صناعة الفيديو كليب',
        catdesc : ' نترجم مشاعركم إلى نغمات كلمـــــات ولقطـــات تلامس القلــــوب عبر عـــن رسالتكـم بأسلـــوب ملفــــــت.',
        catshowreal  : VIDEOCLIPSVideo,
            header: 'أرامكو عيد الفطر ٢٠٢٣',
            description: [
                "من كل بقاع الأرض تختلف عاداتنا كمسلمين في استقبال العيد ولكن فرحتنا مؤتلفة، في قالب إبداعي غنائي كتبنا وأنتجنا أغنية عيد الفطر ٢٠٢٣ مع أرامكو   ."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893027134?h=b32a474834&title=0&byline=0&portrait=0" ,
            ]
        },

        
        {
            id: '17',
            category: 'videoclips',
            catText : ' صناعة الفيديو كليب',
        catdesc : ' نترجم مشاعركم إلى نغمات كلمـــــات ولقطـــات تلامس القلــــوب عبر عـــن رسالتكـم بأسلـــوب ملفــــــت.',
        catshowreal  : VIDEOCLIPSVideo,
            header: ' إثراء اليوم الوطني ٩١',
            description: [
                "صنعنا تحفة فنية لا مثيل لها..  بالتعاون مع إثراء أنتجنا ألحانًا تتغنى بوطننا الحبيب احتفالًا بذكراه الـ 91."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/660202891?h=d5bae7b4b3&title=0&byline=0&portrait=0" ,
            ]
        },

        
        {
            id: '18',
            category: 'videoclips',
            catText : ' صناعة الفيديو كليب',
        catdesc : ' نترجم مشاعركم إلى نغمات كلمـــــات ولقطـــات تلامس القلــــوب عبر عـــن رسالتكـم بأسلـــوب ملفــــــت.',
        catshowreal  : VIDEOCLIPSVideo,
            header: '  أرامكو عيد الأضحى ٢٠٢٣',
            description: [
                "في قالب عاطفي مؤثر كتبنا وأنتجنا وعزفنا موسيقانا،  لنروي قصة دارت في صالة منزل أسرة محاطة بالحب والرعاية، حيث نرى الأعياد على مر السنوات، ونتابع قصة طفل وهو يكبر، يتبدل الزمن والعمر، ويزداد الأفراد، لكن الفرحة مستمرة، كل عام بتفاصيل مختلفة."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893019794?h=80b331d068&title=0&byline=0&portrait=0" ,
            ]
        },

            
        {
            id: '19',
            category: 'videoclips',
            catText : ' صناعة الفيديو كليب',
        catdesc : ' نترجم مشاعركم إلى نغمات كلمـــــات ولقطـــات تلامس القلــــوب عبر عـــن رسالتكـم بأسلـــوب ملفــــــت.',
        catshowreal  : VIDEOCLIPSVideo,
            header: '  عيد الفطر أرامكو  ٢٠٢١   ',
            description: [
                "عيد الفطر لعام ٢٠٢١، كان غير عن كل الأعياد اللي مضت، احتفلنا فيه مع أرامكو لنوثق التزامنا بالإجراءات الاحترازية ونتغلب على تحديات كورونا.                 "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898314626?h=4d61de8ea1&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '20',
            category: 'documantaries',
            catText : ' إنتاج الوثائقيات ',
        catdesc : ' نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
        catshowreal  : DOCUMANTARIESVideo,
            header: '  أسبوع المناخ  ',
            description: [
                "عرضنا بأسلوبنا الخاص تجربة عاطفية وغنية بالمعلومات تؤكد الجهود المستمرة للمملكة في حل تحديات المناخ اليوم من أجل غد أكثر إشراقًا للأجيال القادمة. يقود السرد تساؤلات الأطفال، فبهم ولأجلهم نصنع المستقبل. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898047687?h=f513a8e251&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '21',
            category: 'documantaries',
            catText : ' إنتاج الوثائقيات ',
        catdesc : ' نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
        catshowreal  : DOCUMANTARIESVideo,
            header: '  بنك المنشآت الصغيرة والمتوسطة   ',
            description: [
                " على الطريقة الاحترافية لعرض المعلومات وتبسيطها، كتبنا وأنتجنا هذا الفيلم التعريفي لبنك المنشآت الصغيرة والمتوسطة يعرفنا بمنتجات فنتك المميزة."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893014779?h=a397f6f31e&title=0&byline=0&portrait=0" ,
            ]
        },


        // {
        //     id: '22',
        //     category: 'documantaries',
        //     catText : ' إنتاج الوثائقيات ',
        // catdesc : ' نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
        // catshowreal  : DOCUMANTARIESVideo,
        //     header: '  مسك للمجتمع  ',
        //     description: [
        //         " كتبنا وأنتجنا هذا الوثائقي بطابع التصوير السينمائي حيث نحتفي مع مسك بالطاقات الشبابية التي تقود رحلة الريادة وتسهم في صنع أثر يمتد. "
        //     ],
        //     vimeolink: [
        //         '',
        //     ]
        // },

        {
            id: '23',
            category: 'documantaries',
            catText : ' إنتاج الوثائقيات ',
        catdesc : ' نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
        catshowreal  : DOCUMANTARIESVideo,
            header: ' قصة الهجرة   ',
            description: [
                "حظينا بشرف كتابة وإنتاج هذا الفيلم الوثائقي الذي يروي قصة النور الإلهي الذي انبعث من مكة إلى المدينة في أعظم رحلة في تاريخنا الإسلامي، وعن تلك الهجرة التي غيرت أثرها وجه الحضارة إلى الأبد. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893017894?h=1f214fde1b&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '24',
            category: 'documantaries',
            catText : ' إنتاج الوثائقيات ',
        catdesc : ' نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
        catshowreal  : DOCUMANTARIESVideo,
            header: '   مبادرة أرامكو لزراعة وإنتاج القهوة  ',
            description: [
                " بأسلوب عفوي وشيق، يتحدث إلينا المزراعون في جبال جازان الآسرة عن جازان عن مبادرة أرامكو لزراعة وإنتاج القهوة. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/894100452?h=b3fa0e84a4&title=0&byline=0&portrait=0" ,
            ]
        },

        // {
        //     id: '25',
        //     category: 'documantaries',
        //     catText : ' إنتاج الوثائقيات ',
        // catdesc : ' نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
        // catshowreal  : DOCUMANTARIESVideo,
        //     header: '  سمو الأمير طلال بن عبدالعزيز آل سعود  ',
        //     description: [
        //         " كتبنا وأنتجنا الفيلم الوثائقي الذي يسلط الضوء على نشأة ونمو الجامعة العربية المفتوحة، والتي تهدف إلى جعل التعليم العالي في متناول الجميع  كما نستكشف القيادة الحكيمة لمؤسسها سمو الأمير طلال بن عبدالعزيز آل سعود، الذي أدرك أهمية التعليم في تشكيل مستقبل العالم العربي."
        //     ],
        //     vimeolink: [
        //         '',
        //     ]
        // },

        {
            id: '26',
            category: 'documantaries',
            catText : ' إنتاج الوثائقيات ',
        catdesc : ' نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
        catshowreal  : DOCUMANTARIESVideo,
            header: '  أرامكو قصة إثراء ',
            description: [
                " كتبنا وأنتجنا هذا الفيلم الوثائقي لنسلط الضوء على مركز الملك عبدالعزيز الثقافي العالمي - إثراء الذي بُني ليجسد الذاكرة ويزيد من فخر الوطن.                "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893698501?h=ec57ad25fe&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '27',
            category: 'documantaries',
            catText : ' إنتاج الوثائقيات ',
        catdesc : ' نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
        catshowreal  : DOCUMANTARIESVideo,
            header: '   شركة نقل وتقنية المياه   ',
            description: [
                " منذ 1974 م ونحن نرى جهود المؤسسة العامة لتحلية المياه في العمل على مشاريعها الكبرى في إنتاج المياه المحلاة  تحدث لنا المسؤولون في هذا الفيلم الوثائقي عن إنجازاتهم المتواصلة في عملية نقل وتقنية المياه."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/896458189?h=a17f8352a7&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '28',
            category: 'documantaries',
            catText : ' إنتاج الوثائقيات ',
        catdesc : ' نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
        catshowreal  : DOCUMANTARIESVideo,
            header: '  رحلة الابتكار في معهد الأبحاث والابتكار وتقنيات التحلية  ',
            description: [
                " انطلقنا في رحلة الابتكار مع معهد الأبحاث والابتكار وتقنيات التحلية لمواجهة تحديات قطاع المياه، ومع توثيقنا لحديث أبرز المختصين في القطاع.  "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898126602?h=661ed5a29a&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '29',
            category: 'documantaries',
            catText : ' إنتاج الوثائقيات ',
        catdesc : ' نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
        catshowreal  : DOCUMANTARIESVideo,
            header: 'إثراء - خداع بصري ',
            description: [
                "مع إثراء نستمتع بعروض الفنان لوكاس دي جياكومو في الخداع البصري، بإعداد حلقات في مواقع مختلفة حول إثراء تمنح الفائدة والمتعة في ظروف التباعد الاجتماعي أثناء الجائحة. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898369307?h=031b6d8929&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '30',
            category: 'documantaries',
            catText : ' إنتاج الوثائقيات ',
        catdesc : ' نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
        catshowreal  : DOCUMANTARIESVideo,
            header: '  بايكون   ',
            description: [
                " أعدنا إحياء شخصية العالم الشهير آينشتاين ببناء موقع تصوير مبتكر واستخدام تقنيات تحرير وتعديل إبداعي تعيد بنا إلى الماضي وتأخذنا إلى المستقبل."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/526859151?h=0bf52dab6b&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '31',
            category: 'documantaries',
            catText : ' إنتاج الوثائقيات ',
        catdesc : ' نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
        catshowreal  : DOCUMANTARIESVideo,
            header: '  يوم في حياة مزارع في جازان ',
            description: [
                " شاركنا مزارع بن من جازان تجربته مع مبادرة أرامكو لزراعة القهوة، والهدف من هذا الوثائقي إبراز أثر المبادرة على مزارعي البن المحليين."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/896586092?h=494596537f&title=0&byline=0&portrait=0",
            ]
        },



        {
            id: '32',
            category: 'documantaries',
            catText : ' إنتاج الوثائقيات ',
        catdesc : ' نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
        catshowreal  : DOCUMANTARIESVideo,
            header: 'الفيديو الإعلاني لمكاملة',
            description: [
                "  أدخلوا مجال الطيران في مكملة، التي كانت ذات يوم جزءًا متواضعًا من قطاع الطيران في أرامكو. لقد وثقنا تحول مكملة من كونها جناحًا فرعيًا إلى كيان مستقل. بلمسة سينمائية، أبرزنا طموحاتها واحتفلنا بإنجازاتها البارزة. في نسيج من الرسوم الإبداعية، كشفنا عن الهوية الجديدة والمستقبل الرؤيوي لمكملة."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893028342?h=73c0c13d3e&title=0&byline=0&portrait=0" ,
            ]
        },



        {
            id: '33',
            category: 'tellyourstory',
            catText : ' توثيق القصص',
        catdesc : ' نحول قصصكم الى حكايات مؤثرة تلامس المشاعر وتخلد اللحظات الثمينة',
        catshowreal  : TELLYOURSTORYVideo,
            header: '   المؤسسة العامة لتحلية المياه المالحة  اليوم الوطني ٩٣  ',
            description: [
                "عند البدء على هذا العمل، فكرنا في قصص الأبطال الخارقين التي تكررت على مسامعنا قبل النوم، واستنتجنا بأن البطل ليس من يملك قوى خارقة  بل من يملك حلم ويستمر على الطريق لتحقيقه  وليس هنالك مثال على تحقيق الأحلام إلا المؤسسة العامة لتحلية المياه، سردنا الإنجازات التي سطع نورها في اليوم الوطني السعودي في ذكراه الـ ٩٣."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893022985?h=6ac74150eb&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '34',
            category: 'tellyourstory',
            catText : ' توثيق القصص',
        catdesc : ' نحول قصصكم الى حكايات مؤثرة تلامس المشاعر وتخلد اللحظات الثمينة',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' أرامكو - يوم التأسيس ',
            description: [
                " في إعلان أرامكو ليوم التأسيس عدنا بالزمن لنرى إنجازات أرامكو التي جعلت من الصحراء واحة من العمران، العمل كتب بأقلامنا وأنتج على يد فريقنا المحترف."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893018763?h=48a97bc7d7&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '35',
            category: 'tellyourstory',
            catText : ' توثيق القصص',
        catdesc : ' نحول قصصكم الى حكايات مؤثرة تلامس المشاعر وتخلد اللحظات الثمينة',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' قصص إثراء - فيصل بالطيور ',
            description: [
                " بأسلوبنا السينمائي الوثائقي، أنتجنا هذا الفيلم القصير، الذي نتتبع فيه قصة المبدع فيصل بالطيور، منتج وصانع أفلام، وهو يحكي لنا عن مسيرته كرائد في المجال السينمائي في المملكة."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893015901?h=6cb4ae21b2&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '36',
            category: 'tellyourstory',
            catText : ' توثيق القصص',
        catdesc : ' نحول قصصكم الى حكايات مؤثرة تلامس المشاعر وتخلد اللحظات الثمينة',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' قصص إثراء إبراهيم حجاج ',
            description: [
                " تتبعنا إبراهيم الحجاج وهو يروي لنا قصته منذ بداياته في المجال الفني والمراحل المختلفة في مسيرته وكيف كانت انطلاقته من إثراء، من سلسلة قصص إثراء التي تمت كتابتها وأنتجاها من قبل فريقنا المميز. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898359682?h=116168dc60&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '37',
            category: 'tellyourstory',
            catText : ' توثيق القصص',
        catdesc : ' نحول قصصكم الى حكايات مؤثرة تلامس المشاعر وتخلد اللحظات الثمينة',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' الأمير طلال بن عبدالعزيز',
            description: [
                "  أنتجنا هذا الفيلم الوثائقي لنسلط الضوء على نشأة ونمو الجامعة العربية المفتوحة، والتي تهدف إلى جعل التعليم العالي في متناول الجميع. كما نستكشف القيادة الحكيمة لمؤسسها سمو الأمير طلال بن عبدالعزيز آل سعود، الذي أدرك أهمية التعليم في تشكيل مستقبل العالم العربي. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898065519?h=309ef815f8&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '38',
            category: 'tellyourstory',
            catText : ' توثيق القصص',
        catdesc : ' نحول قصصكم الى حكايات مؤثرة تلامس المشاعر وتخلد اللحظات الثمينة',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' قصة التابلاين',
            description: [
                " تم إنتاج هذا الفيديو تكريمًا لأول موقع للتراث الصناعي في البلاد. نتعرف من خلاله على أهمية خط الأنابيب وكيف أثر على المملكة خلال تلك الفترة."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/645681608?h=81c388cc5f&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '39',
            category: 'tellyourstory',
            catText : ' توثيق القصص',
        catdesc : ' نحول قصصكم الى حكايات مؤثرة تلامس المشاعر وتخلد اللحظات الثمينة',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' قصص إثراء بيتر  ساندرز',
            description: [
                " نأخذكم معنا ومع المصور بيتر ساندرز برحلة شيقة عن مسيرته في التصوير وهو حاملًا عدسته الاحترافية، ليخبرنا عن اهتمامه بأهم حدث إسلامي وهو هجرة الرسول صلى الله عليه وسلم. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/894082923?h=cee0153ef7&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '40',
            category: 'tellyourstory',
            catText : ' توثيق القصص',
        catdesc : ' نحول قصصكم الى حكايات مؤثرة تلامس المشاعر وتخلد اللحظات الثمينة',
        catshowreal  : TELLYOURSTORYVideo,
            header: 'جائزة التميز الإعلامي - نجران ',
            description: [
                " كان لنا دور بارز في جائزة التميز الإعلامي، انتقلنا إلى نجران بتاريخها العريق و عطائها الزراعي وأجوائها الساحرة لم تكتفي بهذا وحسب بل أنجبت لنا المبدع أحمد آل مشرف الذي كُرم في الحفل على  تمثيله الشباب السعودي وطاقاته خير تمثيل..  كان لنا الشرف أن نرافقه في نجران ونروي قصته من خلال عدستنا. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/477979772?h=1841558fe0&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '41',
            category: 'tellyourstory',
            catText : ' توثيق القصص',
        catdesc : ' نحول قصصكم الى حكايات مؤثرة تلامس المشاعر وتخلد اللحظات الثمينة',
        catshowreal  : TELLYOURSTORYVideo,
            header: '  البرنامج الوطني لتقنية المعلومات - عبدالله النملة',
            description: [
                " شاركنا عبدالله النملة رائد أعمال كيف استفادت شركته من مبادرة تمويل نمو التابعة للبرنامج الوطني لتقنية المعلومات وتأثير المبادرة الإيجابي على قدرتهم على طرح الشركة في السوق الموازي."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898321671?h=0d89945395&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '42',
            category: 'tellyourstory',
            catText : ' توثيق القصص',
        catdesc : ' نحول قصصكم الى حكايات مؤثرة تلامس المشاعر وتخلد اللحظات الثمينة',
        catshowreal  : TELLYOURSTORYVideo,
            header: '   الأمير بدر بن عبد المحسن آل سعو  ',
            description: [
                " تشرفنا بأن نكون جزءًا من إنتاج هذه المقابلة الثرية مع سمو الأمير بدر بن عبد المحسن آل سعود، يأخذنا فيه في جولة حول شعره وفنه وأدبه."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/660203816?h=3b12e876c6&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '43',
            category: 'tellyourstory',
            catText : ' توثيق القصص',
        catdesc : ' نحول قصصكم الى حكايات مؤثرة تلامس المشاعر وتخلد اللحظات الثمينة',
        catshowreal  : TELLYOURSTORYVideo,
            header: 'قصص إثراء سارة الرحيلي ',
            description: [
                "  بأسلوب سينمائي عاطفي، يأخذنا الفيديو في رحلة مع المحامية سارة الرحيلي، قارئة العام 2016 بمسابقة اقرأ. نتوقف في محطات ملهمة من حياتها مع الكتب والفكر، ومسيرتها التعليمية الحافلة بالإنجازات من سلسلة قصص إثراء التي تمت كتابة جميع قصصها وإنتاجها من قبل فريقنا المذهل. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898862934?h=229c4e0f38&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '44',
            category: 'animation',
            catText : ' الرسوم المتحركة ثنائية وثلاثية الأبعاد',
        catdesc : 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
        catshowreal  : ANIMATIONVideo,
            header: '  المجدوعي اليوم الوطني ٩٢2 ',
            description: [
                "احتفينا مع المجدوعي القابضة في اليوم الوطني ٩٢ في صنع عمل إبداعي مستعينين بعنصر الرسوم المتحركة ثنائية وثلاثية الأبعاد.   "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893020707?h=2169beec6d&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '45',
            category: 'animation',
            catText : ' الرسوم المتحركة ثنائية وثلاثية الأبعاد',
        catdesc : 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
        catshowreal  : ANIMATIONVideo,
            header: ' المؤسسة العامة لتحلية المياه المالحة يوم التأسيس',
            description: [
                " في ذكرى يوم التأسيس عدنا بالزمن لنرى التطورات التي مر من خلالها الماء منذ البدايات وإلى يومنا، في قالب إبداعي تاريخي، جددنا مشاعر الفخر للمؤسسة العامة لتحلية المياه. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893022321?h=6ac9a8f7a9&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '46',
            category: 'animation',
            catText : ' الرسوم المتحركة ثنائية وثلاثية الأبعاد',
        catdesc : 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
        catshowreal  : ANIMATIONVideo,
            header: '   إثراء والخطوط السعودية',
            description: [
                "سُعدنا بمشاركتنا بإنتاج الفيديو الإعلاني لمسابقة إثراء والخطوط السعودية للتصميم والتي تسعى لدعم المبدعين وتوفير منصات عالمية لإظهار إبداعاتهم."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893702984?h=f24ddb6990&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '47',
            category: 'animation',
            catText : ' الرسوم المتحركة ثنائية وثلاثية الأبعاد',
        catdesc : 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
        catshowreal  : ANIMATIONVideo,
            header: '    وزارة الثقافة يوم التأسيس ',
            description: [
                " بعناصر بصرية مميزة وبجهود من فريق بروإفكت، تم كتابة وإنتاج فيديو يوم التأسيس مع وزارة الثقافة بطابع يملؤه الفخر نوثق الأحداث التي وقعت في تأسيس الدولة السعودية الأولى."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898114244?h=8cb84580c2&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '48',
            category: 'animation',
            catText : ' الرسوم المتحركة ثنائية وثلاثية الأبعاد',
        catdesc : 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
        catshowreal  : ANIMATIONVideo,
            header: '  المؤسسة العامة لتحلية المياه يوم التأسيس ٢٠٢٣',
            description: [
                "  احتفاء بذكرى يوم التأسيس لعام2023، كتبنا وأنتجنا فيلم خاص بمسيرة المملكة العربية السعودية منذ ثلاثة قرون، بالتزامن مع تاريخ تحلية المياه منذ ذلك العصر إلى الآن. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898123972?h=6b192a9ac6&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '49',
            category: 'animation',
            catText : ' الرسوم المتحركة ثنائية وثلاثية الأبعاد',
        catdesc : 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
        catshowreal  : ANIMATIONVideo,
            header: 'الذكرى الـ ٦٠ لتأسيس جمعية مهندسي البترول',
            description: [
                "بمناسبة إكمال جمعية مهندسي البترول ٦٠ عامًا على تأسيسها، أنتجنا هذا العمل بالاستعانة  لنوثق قصة تأسيسها والإنجازات التي حققتها مع الاستعانة بعناصر الجرافيكس. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898306115?h=42a5d54b5e&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '50',
            category: 'animation',
            catText : ' الرسوم المتحركة ثنائية وثلاثية الأبعاد',
        catdesc : 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
        catshowreal  : ANIMATIONVideo,
            header: '  Children\'s Literary Week ',
            description: [
                " كتبنا وأنتجنا فيلم جرافيكس إعلاني لفعالية أسبوع الطفل الأدبي من هيئة الأدب والنشر والترجمة، يدعو الإعلان بأسلوب تشويقي للتسجيل في الأنشطة والبرامج المتنوعة التي تتضمنها الفعالية. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893693740?h=aea86953fd&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '51',
            category: 'animation',
            catText : ' الرسوم المتحركة ثنائية وثلاثية الأبعاد',
        catdesc : 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
        catshowreal  : ANIMATIONVideo,
            header: ' تقويم إثراء ٢٠٢١',
            description: [
                "في كل عام نترقب المفاجآت التي تقدمها إثراء لزوارها.. رافقناهم في هذا الفيديو لنرى ماذا يحمل تقويم 2021 من فعاليات وبرامج مميزة. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898363242?h=9bb29ee09f&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '52',
            category: 'animation',
            catText : ' الرسوم المتحركة ثنائية وثلاثية الأبعاد',
        catdesc : 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
        catshowreal  : ANIMATIONVideo,
            header: ' بطولة آسيا لكرة اليد للرجال ٢٠١٢',
            description: [
                "شاركنا بإنتاج فواصل تلفزيونية لبطولة آسيا لكرة اليد للرجال في عام 2012."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898283672?h=5c30370088&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '53',
            category: 'animation',
            catText : ' الرسوم المتحركة ثنائية وثلاثية الأبعاد',
        catdesc : 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
        catshowreal  : ANIMATIONVideo,
            header: ' بطولة آسيا لكرة اليد للرجال ٢٠١٢ ',
            description: [
                "شاركنا بإنتاج فواصل تلفزيونية لبطولة آسيا لكرة اليد للرجال في عام 2012."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898285349?h=d32ec0a09a&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '54',
            category: 'animation',
            catText : ' الرسوم المتحركة ثنائية وثلاثية الأبعاد',
        catdesc : 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
        catshowreal  : ANIMATIONVideo,
            header: ' بطولة آسيا لكرة اليد للرجال ٢٠١٢ ',
            description: [
                "شاركنا بإنتاج فواصل تلفزيونية لبطولة آسيا لكرة اليد للرجال في عام 2012."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898288016?h=31094edd83&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '55',
            category: 'animation',
            catText : ' الرسوم المتحركة ثنائية وثلاثية الأبعاد',
        catdesc : 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
        catshowreal  : ANIMATIONVideo,
            header: '  بطولة آسيا لكرة اليد للرجال ٢٠١٢ ',
            description: [
                "شاركنا بإنتاج فواصل تلفزيونية لبطولة آسيا لكرة اليد للرجال في عام 2012."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898288759?h=3e5cd6b1cf&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '56',
            category: 'animation',
            catText : ' الرسوم المتحركة ثنائية وثلاثية الأبعاد',
        catdesc : 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
        catshowreal  : ANIMATIONVideo,
            header: '  مقدمة بطولة آسيا لكرة اليد للرجال ٢٠١٢',
            description: [
                " أنتجنا مقدمة بطولة آسيا لكرة اليد للرجال في عام 2012."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898290216?h=8e31a1a2f7&title=0&byline=0&portrait=0" ,
            ]
        },

        
        {
            id: '57',
            category: 'tvservices',
            catText : 'خدمة التلفزيون ',
        catdesc : 'نحن نقدم خدمات تلفزيونية شاملة، نوفر حلول بث عالية الجودة.',
        catshowreal  : TVSERVICESVideo,
        header: ' مقدمة برنامج هلا بالمونديال ',
        description: [
            " عشنا أجواء المونديال وذلك من خلال تعاوننا مع التلفزيون السعودي في إنتاج مقدمة برنامج هلا بالمونديال الذي يغطي أهم أحداث كأس العالم للأندية. "
        ],
            vimeolink: [
                "https://player.vimeo.com/video/899236967?h=98448d7b91&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '58',
            category: 'tvservices',
            catText : 'خدمة التلفزيون ',
        catdesc : 'نحن نقدم خدمات تلفزيونية شاملة، نوفر حلول بث عالية الجودة.',
        catshowreal  : TVSERVICESVideo,
        header: ' مقدمة الاكسبو',
        description: [
            " احتفينا مع فوز المملكة باضافة حدث إكسبو 2030 من خلال فيديو جرافيكس افتتاحي للتغطية."
        ],
            vimeolink: [
                "https://player.vimeo.com/video/898998851?h=e5b321c4d9&title=0&byline=0&portrait=0" ,
            ]
        },



        {
            id: '59',
            category: 'tvservices',
            catText : 'خدمة التلفزيون ',
        catdesc : 'نحن نقدم خدمات تلفزيونية شاملة، نوفر حلول بث عالية الجودة.',
        catshowreal  : TVSERVICESVideo,
        header: '  مقدمة دوري يلو الدرجة الأولى ',
        description: [
                " تعاونا مع دوري يلو الدرجة الأولى في إنتاج المقدمة الخاصة به."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/899022207?h=6c7fd81ef3&title=0&byline=0&portrait=0" ,
            ]
        },



        {
            id: '60',
            category: 'tvservices',
            catText : 'خدمة التلفزيون ',
        catdesc : 'نحن نقدم خدمات تلفزيونية شاملة، نوفر حلول بث عالية الجودة.',
        catshowreal  : TVSERVICESVideo,
            header: '3x3 مقدمة برنامج ',
            description: [
                "سعدنا بأن نكون جزءا من البرنامج الترفيهي الثقافي 3x٣ بإنتاج فيديو المقدمة الافتتاحية."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898992686?h=e7e5a9571c&title=0&byline=0&portrait=0" ,
            ]
        },



        {
            id: '61',
            category: 'tvservices',
            catText : 'خدمة التلفزيون ',
        catdesc : 'نحن نقدم خدمات تلفزيونية شاملة، نوفر حلول بث عالية الجودة.',
        catshowreal  : TVSERVICESVideo,
            header: ' مقدمة برنامج الموقف ',
            description: [
                " شاركنا التلفزيون السعودي في إنتاج مقدمة برنامج الموقف، الذي يهتم بالأخبار السياسية محليًا وعالميًا."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/899000475?h=a3e41a9802&title=0&byline=0&portrait=0" ,
            ]
        },



        {
            id: '62',
            category: 'tvservices',
            catText : 'خدمة التلفزيون ',
        catdesc : 'نحن نقدم خدمات تلفزيونية شاملة، نوفر حلول بث عالية الجودة.',
        catshowreal  : TVSERVICESVideo,
            header: '   مقدمة قمة الرياض',
            description: [
                " نفخر بمشاركتنا في إنتاج مقدمة قمة الرياض بين مجلس التعاون لدول الخليج العربية ودول رابطة الآسيان."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/899000785?h=5fa241d2b0&title=0&byline=0&portrait=0" ,
            ]
        },



        {
            id: '63',
            category: 'eventhighlights',
            catText : 'تغطية الفعاليات  ',
        catdesc : '         نحول فعاليتكم إلى تجــــارب لا تـــــنســــــــى، ونوثقها بدقــــة وإبـــداع  لتعيش فــــــــي الذاكرة.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: '   ختام أسبوع المناخ ',
            description: [
                "نفخر لكوننا جزء من نجاح أسبوع   المقام في مدينة الرياض، حيث قمنا بتوثيق جهود المملكة المستمرة في حل تحديات المناخ من أجل غد أكثر إشراقًا للأجيال القادمة."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898059664?h=f14b869505&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '64',
            category: 'eventhighlights',
            catText : 'تغطية الفعاليات  ',
        catdesc : '         نحول فعاليتكم إلى تجــــارب لا تـــــنســــــــى، ونوثقها بدقــــة وإبـــداع  لتعيش فــــــــي الذاكرة.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: ' أسبوع الثقافة في فيتنام ',
            description: [
                "أحد أبدع أعمالنا والذي تم العمل عليه بكل شغف، أيام فيتنام الثقافية حيث ازدان مركز الملك عبدالعزيز الثقافي العالمي (إثراء) بثقافة التنين الأحمر الفيتنامية خلال أيام إثراء الثقافية."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898863962?h=a2ee00fb64&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '65',
            category: 'eventhighlights',
            catText : 'تغطية الفعاليات  ',
        catdesc : '         نحول فعاليتكم إلى تجــــارب لا تـــــنســــــــى، ونوثقها بدقــــة وإبـــداع  لتعيش فــــــــي الذاكرة.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: ' إثراء داون تاون  ',
            description: [
                " في أجواء الشتاء الرائعة، تنقلنا بين العديد من الأنشطة والفعاليات التي وثقناها في إثراء داون تاون."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/507461487?h=054d8cc4b1&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '66',
            category: 'eventhighlights',
            catText : 'تغطية الفعاليات  ',
        catdesc : '         نحول فعاليتكم إلى تجــــارب لا تـــــنســــــــى، ونوثقها بدقــــة وإبـــداع  لتعيش فــــــــي الذاكرة.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: '  إقرأ  ',
            description: [
                " تحدثوا معنا سامي ومحمد وأمجاد، عن تجربتهم وفوزهم سابقًا بمسابقة إثراء القراءة التي تقدمها إثراء سنويًا كما وثقنا جهود إثراء في نشر ثقافة القراءة لتصل إلى جميع فئات مراحل التعليم"
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898361736?h=ca8b38d445&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '67',
            category: 'eventhighlights',
            catText : 'تغطية الفعاليات  ',
        catdesc : '         نحول فعاليتكم إلى تجــــارب لا تـــــنســــــــى، ونوثقها بدقــــة وإبـــداع  لتعيش فــــــــي الذاكرة.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: 'صيف موسم الشرقية',
            description: [
                "في هذا الفيديو تنقلنا بين مدن المنطقة الشرقية وتلونت أعيننا في مناظرها الجمالية.. في موسم الشرقية #يلا_شرقية."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898299154?h=6bdb7ad240&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '68',
            category: 'eventhighlights',
            catText : 'تغطية الفعاليات  ',
        catdesc : '         نحول فعاليتكم إلى تجــــارب لا تـــــنســــــــى، ونوثقها بدقــــة وإبـــداع  لتعيش فــــــــي الذاكرة.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: ' موسم الشرقية ',
            description: [
                " وفي جانب التغطيات، فخورين بتوثيقنا للحظات لا تنسى في موسم الشرقية بمختلف الأنشطة والفعاليات."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/922200065?h=c3b15d86ae&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '69',
            category: 'eventhighlights',
            catText : 'تغطية الفعاليات  ',
        catdesc : '         نحول فعاليتكم إلى تجــــارب لا تـــــنســــــــى، ونوثقها بدقــــة وإبـــداع  لتعيش فــــــــي الذاكرة.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: ' برنامج الاتزان الرقمي ',
            description: [
                "قمنا بتغطية مبادرة الاتزان الرقمي syns التي أقيمت في إثراء، حيث تهدف المبادرة لفهم واقع التقنية اليوم وكيف أصبحت تؤثر على حياتنا، من خلال إقامة ندوات مع عدة مختصين في هذا المجال."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893695294?h=f702d4d3c0&title=0&byline=0&portrait=0" ,
            ]
        },


        
        {
            id: '70',
            category: 'bts',
            catText : 'BTS',
        catdesc : 'نحن نلتقط جوهر الكواليس (BTS)، نقدم نظرة فريدة إلى عملية الإبداع.',
        catshowreal  : BTSVideo,
        header: ' خلف الكواليس لأسبوع المناخ ',
        description: [
            " مجهود مبذول لما خلف الكواليس لصناعة عمل أسبوع المناخ BTS في رحلة مليئة بالتحديات فكرنا، كتبنا هذا العمل العالمي، مستعينين بأحدث التقنيات الإنتاجية الاستوديو الإفتراضي أنتجنا العمل الرسمي لنقل رسالة المملكة العربية السعودية ممثلة بوزارة الطاقة في مؤتمر أسبوع المناخ "
        ],
            vimeolink: [
                                "https://player.vimeo.com/video/893697407?h=ddd16f366f&title=0&byline=0&portrait=0"                ,
            ]
        },


        {
            id: '71',
            category: 'bts',
            catText : 'BTS',
        catdesc : 'نحن نلتقط جوهر الكواليس (BTS)، نقدم نظرة فريدة إلى عملية الإبداع.',
        catshowreal  : BTSVideo,
            header: 'خلف الكواليس نبدأ سوى ',
            description: [
                "ذكريات رائعة وجهود لا مثيل لها..  خلف الكواليس لعمل نبدأ سوى الذي تم إطلاقه في موسم الشرقية. "
            ],
            vimeolink: [
            "https://player.vimeo.com/video/898293302?h=27cb42ead7&title=0&byline=0&portrait=0" ,
            ]
        },

        
        {
            id: '72',
            category: 'audio',
            catText : 'تصميم الهوية السمعية',
        catdesc : 'نصمم هوية صوتية مميزة تعزز علامتكم ميزكم عن التجارية وت المنافسين.',
        catshowreal  : AudioVideo,
            header: '',
            description: [
                ""
            ],
            vimeolink: [
            "" ,
            ]
        },

        {
            id: '73',
            category: 'storage',
            catText : ' الأرشفة والتخزين السحابي',
        catdesc : 'تخزين مستدام في عالم سحابي يحفظ أحلامك ومشاريعك في مكان واحد',
        catshowreal  : StorageVideo,
            header: '',
            description: [
                ""
            ],
            vimeolink: [
            "" ,
            ]
        },















    ];
    









    
    useEffect(() => {
        const playButtons = document.querySelectorAll('.play-video');

        playButtons.forEach(button => {
            const video = button.previousElementSibling; // Assuming the video is always right before the button

            button.addEventListener('click', function() {
                if (video.paused) {
                    video.play();
                    button.style.display = 'none'; // Hide play button
                }
            });

            video.addEventListener('click', function() {
                if (!video.paused) {
                    video.pause();
                    button.style.display = ''; // Show play button

                }
            });
            video.addEventListener('ended', function() {
                button.style.display = ''; // Show play button when video ends
            });
        });
    }, []);


    const query = useQuery();
    const category = query.get('category'); // This extracts the category from the URL

    const filteredProjects = ProjectsData.filter(project => project.category === category);

    if (filteredProjects.length === 0) {
        // Return nothing or an optional message
        return null; // Or, for example, return <div>No projects found for this category.</div>;
    }

    return(
        <>
        


        <section className="work-page-header ar">
            <img className="overlay" src={OverlayImage} alt="overlay"/>

            <div className="container">
                <div className="work-header-content">
                    <p>
                        {filteredProjects[0].catdesc}
                    </p>

                    {/* <h1> W <img src={OLetter} alt="O letter"/> RK </h1> */}
                    <h1> {filteredProjects[0].catText}</h1>
                </div>
            </div>
        </section>






        <section className="our-work ar">
            <div className="container">
                <div className="header">
                    <h2> تحرك ألى الاسفل <img src={Arrow} alt="arrow"/> </h2>

                </div>


                <video src={filteredProjects[0].catshowreal} alt="video main" autoPlay muted loop playsInline> </video>


                <div className="row">


                {filteredProjects.map((project, index) => (
                <div className="col-lg-6 col-md-6 col-sm-12 col-12" key={index}>
                    <div className="project">
                        <h1>{project.header}</h1>
                        <div className="project-video">
                        {project.vimeolink ? (
                                // If project.vimeolink is present, render the iframe
                                <iframe title={project.header} src={project.vimeolink} width="100%" height="450"  frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                            ) : (
                                // If project.vimeolink is not present, render a placeholder or message
                                <h1> لا يوجد فيديو </h1>
                            )}
                        </div>
                        <p>{project.description.join(" ")}</p> {/* Assuming description is an array of strings */}
                        
                    </div>
                </div>
            ))}



                    
                </div>
            </div>
        </section>






        </>
    )
}

export default WorkPage;