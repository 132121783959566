import './App.css';
import { Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";


import AOS from 'aos';
import 'aos/dist/aos.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import LandingAd from './components/LandingAd';
import Services from './components/Services';
import Footer from './components/Footer';
import AboutPage from './components/AboutPage';
import CaseStudyPage from './components/CaseStudyPage';
import WorkPage from './components/WorkPage';
import ContactUs from './components/ContactUs';
import OurClients from './components/Clients';
import DigitalAssests from './components/DigitalAssests';
import AdvancedServices from './components/AdvancedServices';
import NewServices from './components/NewServices';



import NavbarAR from './components/ar/Navbar';
import LandingAR from './components/ar/Landing';
import LandingAdAR from './components/ar/LandingAd';
import ServicesAR from './components/ar/Services';
import FooterAR from './components/ar/Footer';
import AboutPageAR from './components/ar/AboutPage';
import CaseStudyPageAR from './components/ar/CaseStudyPage';
import WorkPageAR from './components/ar/WorkPage';
import ContactUsAR from './components/ar/ContactUs';
import OurClientsAR from './components/ar/Clients';
import DigitalAssestsAR from './components/ar/DigitalAssests';
import AdvancedServicesAR from './components/ar/AdvancedServices';
import NewServicesAR from './components/ar/NewServices';

function App() {
    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, [])
    
return (
    <div className="App">

            <Routes>
            
            <Route path="/" element={
                    <>
                        <Navbar />
                        <Landing />
                        <LandingAd />
                        <NewServices />
                        <OurClients />
                        <Footer />
                    </>
                } />


            <Route path="/about-us" element={
                    <>
                        <Navbar />
                        <AboutPage />
                        <Footer />
                    </>
                } />

                
            <Route path="/case-study" element={
                    <>
                        <Navbar />
                        <CaseStudyPage />
                        <Footer />
                    </>
                } />


            <Route path="/work" element={
                    <>
                        <Navbar />
                        <WorkPage />
                        <Footer />
                    </>
                } />


                

            <Route path="/contact-us" element={
                    <>
                        <Navbar />
                        <ContactUs />
                        <Footer />
                    </>
                } />

                
            <Route path="/digital-assests" element={
                    <>
                        <Navbar />
                        <DigitalAssests />
                        <Footer />
                    </>
                } />


                <Route path="/advanced-services" element={
                    <>
                        <Navbar />
                        <AdvancedServices />
                        <Footer />
                    </>
                } />





                
            <Route path="/ar" element={
                    <>
                        <NavbarAR />
                        <LandingAR />
                        <LandingAdAR />
                        <NewServicesAR />
                        <OurClientsAR />
                        <FooterAR />
                    </>
                } />


            <Route path="/ar/about-us" element={
                    <>
                        <NavbarAR />
                        <AboutPageAR />
                        <FooterAR />
                    </>
                } />

                
            <Route path="/ar/case-study" element={
                    <>
                        <NavbarAR />
                        <CaseStudyPageAR />
                        <FooterAR />
                    </>
                } />


            <Route path="/ar/work" element={
                    <>
                        <NavbarAR />
                        <WorkPageAR />
                        <FooterAR />
                    </>
                } />


                

            <Route path="/ar/contact-us" element={
                    <>
                        <NavbarAR />
                        <ContactUsAR />
                        <FooterAR />
                    </>
                } />

                
            <Route path="/ar/digital-assests" element={
                    <>
                        <NavbarAR />
                        <DigitalAssestsAR />
                        <FooterAR />
                    </>
                } />


                <Route path="/ar/advanced-services" element={
                    <>
                        <NavbarAR />
                        <AdvancedServicesAR />
                        <FooterAR />
                    </>
                } />



        </Routes>



        
    </div>
);
}

export default App;
