

import Wheel from "../images/objects/wheel.png";
import overlayImage from "../images/Rectangle -1.webp";
import  ELetter from "../images/objects/E.png";
import ValueImage from '../images/value/1.webp';
import GoalImage from '../images/goal/1.webp';

import visionImg from "../images/ourvision/1.png";
import visionImg2 from "../images/ourvision/2.png";



import aboutServicesImg from "../images/aboutservices/1.webp";
import whyus from "../images/whyus/1.webp";

import Oletter from "../images/objects/goals.png";
import Cletter from "../images/objects/c.png";
import Circles from "../images/icons/circles.png";

import Badr from '../images/team/badr.png';
import abdullah from '../images/team/abdullah.jpg';
import hmood from '../images/team/hmood.jpg';
import mohamedsaleh from '../images/team/mohamedsaleh.jpg';


import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Navigation} from 'swiper';

import { Link as RouterLink } from "react-router-dom";

function AboutPage(){
    return(
        <>
    

<section className="about-page-header  ar">
    <div className="container">
        <div className="about-page-header-content">
            <h1 data-aos-delay="100" data-aos="fade-in">  نكسر رتابة المعتاد </h1>

                {/* <p>
                    Hello <br/> Friend WE ARE PROEFFECT — A <br/>
                    Creative Production House <br/> BASED IN RIYADH, El Khabar.
                </p> */}

                <h2 data-aos-delay="200" data-aos="fade-in"> نترجم <span data-aos-delay="300" data-aos="fade-in"> الفكرة </span></h2>
                <h3>  إلى <span data-aos-delay="500" data-aos="fade-in"> واقع .</span> </h3>
        </div>
    </div>
</section>




{/*  End about-page-header Section */}



{/*  Start About Ad Section */}



<section className="about-ad ar">
    <div className="container">
        <div className="about-ad-content">
            <h1 data-aos-delay="100" data-aos="fade-in"> عن بروإفكت </h1>

            <p data-aos-delay="200" data-aos="fade-in">
                <strong> لنروي معًا قصة المملكة:  <br></br></strong>
                <br></br>
                مــــــن قــلــــــــب الممــلكــــــة العــــربيــــــة السعوديــــة، انطلــقــــــت بروافكت، رائــــــــــدة الإنتــــاج الإبداعــــي، بنظرة مختلفة في عالم الانتاج الإعلاني والوثائقـــي.
            </p>
        </div>
    </div>
</section>




{/*  End About Ad Section */}




{/*  Start Our Vision Section */}



<section className="our-vision ar">

    <div className="container">
        <div className="our-vision-content">
            <h1> رؤيتنا </h1>

            <p data-aos-delay="200" data-aos="fade-in">

                <strong> مساهماتنا في رؤية المملكة 2030<br></br> </strong>
                <br></br>
                    نؤمـــن ونسعـــى ان يكـــون دورنـــا فعـــــــال فــي تحقيــــــق رؤيـــــــــة المملكـــــــــة 2030 من خلال الإنتاج الإبداعـــي الــــــذي نقـــــوم به لنروي قصة التحول والنمو في المملكة مؤكدين علـــى الابتكار والتنمية المستدامة
                    كركائـــز أساسيـــة فـــي كل مـــا نقـوم بـــه.
            </p>
        </div>
    </div>
</section>

<section className="our-vision-image  ar">
    <img data-aos-delay="100" data-aos="fade-in" src={visionImg} alt="boy playing on phone"/>
</section>





{/*  End Our Vision Section */}


{/*  Start Our Value Section */}

    <section className="our-value ar">
        <div className="container">
            <div className="our-value-content">
                <div className="header">
                    <h3 data-aos-delay="100" data-aos="fade-in"> قيم </h3>
                    <h1 data-aos-delay="200" data-aos="fade-in">  بروإفكت  </h1>
                </div>

                <div data-aos-delay="100" data-aos="fade-in" className="our-value-slider">
                    <div className="buttons">
                        <button className="prev-btn"><i className="las la-arrow-left"></i></button>
                        <button className="next-btn"><i className="las la-arrow-right"></i></button>
                    </div>

                    <Swiper  grabCursor={true} spaceBetween={50} slidesPerView={1} navigation={{
                        nextEl: '.our-value-slider .next-btn',
                        prevEl: '.our-value-slider .prev-btn',
                        }} 
                            modules={[Navigation]}
                            >
                        <SwiperSlide>

                            <div className="value">
                                <h2> 01 </h2>

                                <div>
                                    <h1> التنمية المستدامة: </h1>
                                    <p>
                                    ندرك في بروافكت أهمية التنمية
                                المستــــدامة، ونحــرص علـى دمج
                                ممارساتها فــــــــي جميع أنشطتنا.
                                    </p>

                                    <p>
                                    سعــى إلى تقليل البصمة البيئية
                                    لإنتاجاتنــا مــــن خــلال اســتخــــدام
                                    تقنيات مبتكــرة وصديقــة للبيــئة.

                                    </p>

                                    <p>
                                    نشجع على المسؤولية الاجتماعية
                                    ونشارك فــــي مبادرات تــعـــزز
                                    الوعـــي بأهــميــة الــحــــــاظ عــــلــى
                                    مواردنــــــــا الطبيعية.

                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>

                            <div className="value">
                                <h2> 02 </h2>

                                <div>
                                    <h1> استكشاف آفاق جديدة: </h1>
                                    <p>
                                    مـــع نظرة إلى المستقبل، تسعـــى
                                    بروافكـت إلـــى استكشـــاف آفــــاق
                                    جديدة في مجال الإنتاج الإبداعــي. 

                                    </p>
                                    <p>
                                    ونسخر قدرات الذكـاء الاصطناعــي
                                    باحترافية لتحقيق اهـداف شركائــنا
                                    نبحث عــــــن طــــــرق جديـــدة لدمـج
                                    الواقع الافتراضي والواقـع المعــزز
                                    فــــــي إبداعاتنــــــا
                                    </p>

                                    <p>
                                    نسعى لتقديم تجارب فريدة وغامرة
                                    تتجاوز توقعــــات عملائنــــا وترتقـــي
                                    بالمحتوى الإبداعي إلــــى مستويات
                                    جديــــدة مــــن الابـــتكــــار والتأثيــــــــر.
                                    </p>
                                </div>
                            </div>
                            </SwiperSlide>



                        </Swiper>
                </div>


                <img data-aos-delay="100" data-aos="fade-in" src={ValueImage} alt="boy playing on phone"/>

            </div>
        </div>
    </section>




{/*  Start Our Value Section */}



{/*  Start Our Goals Section */}

<section className="our-goals  ar">
    <img src={overlayImage} className="overlay" alt="overlay"/>
    <div className="container">
        <div className="our-goals-content">
            <div className="header">
                <h2 data-aos-delay="100" data-aos="fade-in"> بروإفكت </h2>
                <h1 data-aos-delay="200" data-aos="fade-in"> مهمتنا </h1>
            </div>


            <div className="body">
                <div className="left">
                    <p data-aos-delay="100" data-aos="fade-in">
                        <strong> في بروافكت مهمتنا </strong>
                        <br></br>
                        <br></br>
                        تتجــــــاوز تقديـــــــــم المحتــــــوى إلــــــى خلق تجــــــارب استثنائيــــــة، تـــــــــــــــروي قصـــــــــص اـــلنجـــــــــاح وتوثـــــــــق الإنـــجــــــــــــــــــازات. نسعــــــى أن نكــــــون الصوت الذي يــــــروي، والعين التـــي تشهد، والقلـــب الذي يشعر بكل مـــا هو استثنائي وملهم فـــي المملكة.
                    </p>

                    {/* <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry’s standard dummy
                        text ever since the 1500s,
                    </p> */}

                    <span></span>
                </div>


                <div data-aos-delay="200" data-aos="fade-in" className="right">
                    <img data-aos-delay="100" data-aos="fade-in"  src={GoalImage} alt="boy playing on phone"/>

                </div>
            </div>
        </div>
    </div>
</section>





{/*  End Our Goals Section */}



{/*  Start Why us Section */}



<section className="why-us ar">
    <div className="container">
        <div className="why-us-content">
            <h2  data-aos-delay="100" data-aos="fade-in"> لماذا  </h2>
            <h1 data-aos-delay="200" data-aos="fade-in"> بروإفكت </h1>
        


        <div data-aos-delay="100" data-aos="fade-in" className="why-us-slider">
            <div   className="buttons">
                <button className="prev-btn"><i className="las la-arrow-left"></i></button>
                <button className="next-btn"><i className="las la-arrow-right"></i></button>

            </div>

            <Swiper grabCursor={true} spaceBetween={50} slidesPerView={1} navigation={{
                        nextEl: '.why-us-slider .next-btn',
                        prevEl: '.why-us-slider .prev-btn',
                        }} 
                            modules={[Navigation]}
                            >
                <SwiperSlide>
                <div className="slide">
                    <div className="res">
                        <h2> 01 </h2>

                        <div>
                            <h1>الابتكــار </h1>
                            <p>
                            سنــــــــوات مــــــــن العمــــــــــــل المتقــــــــن مــــــــــــع أهــــــــم المؤسســــــــات فـــي المملكة.
                            </p>
                        </div>
                    </div>
                </div>
                </SwiperSlide>

                <SwiperSlide>

                <div className="slide">
                    <div className="res">
                        <h2> 02 </h2>

                        <div>
                            <h1> الجـــودة </h1>
                            <p>
                            نستــــخـــــــــدم أحــــــــدث التقنــــيــــــــــــات لضمــــان نتائــــــــــــج تفــــــــــــوق التوقــــعــــــــــــات.
                            </p>
                        </div>
                    </div>
                </div>
                </SwiperSlide>


                <SwiperSlide>

                <div className="slide">
                    <div className="res">
                        <h2> 03 </h2>

                        <div>
                            <h1> الشراكـــــة </h1>
                            <p>
                            نعتبر كل عميل شريكًا في النجاح، نعمل جنبًا إلى جنب لتحقيق رؤيته بكل شغف وإتقان.
                            </p>
                        </div>
                    </div>
                </div>
                </SwiperSlide>


                
                <SwiperSlide>

                <div className="slide">
                    <div className="res">
                        <h2> 04 </h2>

                        <div>
                            <h1> تقنيات حديثة </h1>
                            <p>
                            نستــــخـــــــــدم أحــــــــدث التقنــــيــــــــــــات لضمــــان نتائــــــــــــج تفــــــــــــوق التوقــــعــــــــــــات.
                            </p>
                        </div>
                    </div>
                </div>
                </SwiperSlide>


                <SwiperSlide>

                <div className="slide">
                    <div className="res">
                        <h2> 05 </h2>

                        <div>
                            <h1> خبرة واسعــــة </h1>
                            <p>
                            سنــــــــوات مــــــــن العمــــــــــــل المتقــــــــن مــــــــــــع أهــــــــم المؤسســــــــات فـــي المملكة.
                            </p>
                        </div>
                    </div>
                </div>
                </SwiperSlide>



                </Swiper>


            </div>





        </div>


    </div>

    <img data-aos-delay="100" data-aos="fade-in" src={whyus} alt="boy playing on phone"/>


</section>





{/*  End Why us Section */}



{/*  Start About Services Section */}

<section className="about-services ar">
    <div className="container">
        <div className="about-services-content">
            <div className="left">
                <div>
                    <h1 data-aos-delay="100" data-aos="fade-in"> بروإفكت </h1>
                    <h1 data-aos-delay="200" data-aos="fade-in">  الخدمات </h1>

                </div>

                <p> ابـتــــداءً بالـــفــــكــــــرة
                    مروًرا بالكتابة الإبداعـيــــة
                    انتهــــاءً بالإخــــراج
                    الفـــنـي

                    </p>
            </div>

            <div className="right">
                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1 data-aos-delay="100" data-aos="fade-in">  الإنتاج الإعلاني </h1>
                    <RouterLink to="/ar/work?category=tvc" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>
                
                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1 >  صناعة الفيديو كليب </h1>
                    <RouterLink to="/ar/work?category=videoclips" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>

                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1>  إنتاج الوثائقيات  </h1>
                    <RouterLink to="/ar/work?category=documantaries" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>

                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1>  توثيق القصص  </h1>
                    <RouterLink to="/ar/work?category=tellyourstory" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>



                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1>  الرسوم المتحركة ثنائية وثلاثية الأبعاد </h1>
                    <RouterLink to="/ar/work?category=animation" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>


                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1>  خدمات التلفزيون</h1>
                    <RouterLink to="/ar/work?category=tvservices" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>

                <div data-aos-delay="100" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1>  تغطية الفعاليات </h1>
                    <RouterLink to="/ar/work?category=eventhighlights" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>

                
                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1> BTS </h1>
                    <RouterLink to="/ar/work?category=bts" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>

                    
                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1> الأرشفة والتخزين السحابي </h1>
                    <span></span>
                </div>

                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1> تصميم الهوية السمعية </h1>
                    <span></span>
                </div>

            </div>

        </div>
    </div>

    <img data-aos-delay="100" data-aos="fade-in" src={aboutServicesImg} alt="boy playing on phone"/>

</section>








{/*  End About Services Section */}



{/*  Start Our Team Section */}


<section className="our-team ar">
    <div className="container">
        <div className="our-team-content">
            <div className="header">
                <h1 data-aos-delay="100" data-aos="fade-in"> مجلس  </h1>
                <h1 data-aos-delay="200" data-aos="fade-in"> الإدارة  </h1>
            </div>

            <div data-aos-delay="100" data-aos="fade-in" className="team-slider">
                <Swiper grabCursor={true}  centeredSlides={true} spaceBetween={30} slidesPerView={'auto'} navigation={{
                        nextEl: '.team-slider .next-btn',
                        prevEl: '.team-slider .prev-btn',
                        }} 
                            modules={[Navigation]}
                            >
                                

                                
                                
                    <SwiperSlide>
                    <div className="slide">
                        <div className="member">
                            <img src={Badr} alt="CEO Badr AlZidane"/>

                            <div className="info">
                                <h1>بدر آل زيدان </h1>
                                <h3> الرئيس التنفيذي </h3>
                            </div>
                        </div>
                    </div>
                    </SwiperSlide>


                    <SwiperSlide>

                    <div className="slide">
                        <div className="member">
                            <img src={hmood} alt="CEO Badr AlZidane"/>

                            <div className="info">
                                <h1> حمود الفايز </h1>
                                <h3> مدير تطوير الأعمال </h3>
                            </div>
                        </div>
                    </div>
                    </SwiperSlide>


                    <SwiperSlide>

                    <div className="slide">
                        <div className="member">
                            <img src={abdullah} alt="CEO Badr AlZidane"/>

                            <div className="info">
                                <h1> عبدالله الصقعبي </h1>
                                <h3> مدير التخطيط وبناء الاستراتيجيات </h3>
                            </div>
                        </div>
                    </div>
                    </SwiperSlide>


                    <SwiperSlide>

                    <div className="slide">
                        <div className="member">
                            <img src={mohamedsaleh} alt="CEO Badr AlZidane"/>

                            <div className="info">
                                <h1> محمد الصالح </h1>
                                <h3> رئيس مجلس الإدارة  </h3>
                            </div>
                        </div>
                    </div>
                    </SwiperSlide>





                    </Swiper>


                <div className="buttons">
                    <button className="prev-btn"><i className="las la-angle-left"></i></button>
                    <button className="next-btn"><i className="las la-angle-right"></i></button>
                </div>
            </div>


            
        </div>
    </div>
</section>




{/*  */}


{/*  End Our Team Section */}




        </>
    )
}

export default AboutPage;   