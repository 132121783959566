function LandingAd(){
    return(
        <>

            <div className="landing-ad ar">
                <div className="container">
                    <div className="landing-ad-content">
                        <h1 data-aos-delay="100" data-aos="fade-in">
                            نوثق اللحظة صوتا وصورة
                        </h1>

                        {/* <p>
                            WE ARE PROEFFECT  <br/>
                            Creative Production House <br/> 
                        </p> */}
                    </div>  
                </div>
            </div>
        
        </>
    )
}

export default LandingAd;