
import { Link as RouterLink } from "react-router-dom";

import React, { useState } from 'react';

import Logo from '../images/logo/logo.png';
import BlackLogo from '../images/logo/blacklogo.png';

import  { useEffect } from 'react';
import $ from 'jquery';
import TwLogo from '../images/footer/twitter.png';


function Navbar(){

    const MouseTracker = () => {
        const [position, setPosition] = useState({ x: 0, y: 0 });
    
        useEffect(() => {
            const updateMousePosition = (e) => {
                setPosition({ x: e.pageX, y: e.pageY });
            };
    
            document.addEventListener('mousemove', updateMousePosition);
    
            return () => {
                document.removeEventListener('mousemove', updateMousePosition);
            };
        }, []);
    
        return (
            <div
                className="mouse-tracker"
                style={{
                    position: 'absolute',
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    left: position.x - 50,
                    top: position.y - 50,
                    pointerEvents: 'none',
                }}
            ></div>
        );
    };

    
    // State to manage the visibility of the side nav
    const [isSideNavActive, setIsSideNavActive] = useState(false);

    // Function to open side nav
    const openSideNav = () => setIsSideNavActive(true);

    // Function to close side nav
    const closeSideNav = () => setIsSideNavActive(false);

    window.scrollTo({
        top: 0,
        behavior: 'smooth', // For a smooth scrolling
    });
    
    return(
        <>

<MouseTracker />

        <nav className=" ar">
            <div className="container">
                <div className="nav-content">
                    <div data-aos-delay="100" data-aos="fade-in" className="logo">
                        <RouterLink to="/">
                            <img src={Logo} alt="proeffect logo"/>
                        </RouterLink>
                    </div>

                    <div data-aos-delay="200" data-aos="fade-in" className="menu-btn">
                        <RouterLink to="/">
                            English
                        </RouterLink>
                        <button onClick={openSideNav} type="button" aria-label="open side nav"> <i class="las la-bars"></i> </button>
                    </div>
                </div>
            </div>
        </nav>





        <div className={`side-nav ar ${isSideNavActive ? 'active' : ''}`}>
        <div className="side-nav-content">
            <div className="top">
                <div className="header">
                    <img src={BlackLogo} alt="proeffect black logo"/>
    
                    <button onClick={closeSideNav} aria-label="close side nav"> <i className="las la-times"></i> </button>
                </div>
    
                <ul onClick={closeSideNav}>
                    <li>
                        <span></span>
                        <RouterLink to="/ar">
                            الرئيسية
                        </RouterLink>
                    </li>
    
                    {/* <li>
                        <span></span>

                        <a href="/#services">
                            SERVICES
                        </a>
                    </li> */}
    
                    <li>
                        <span></span>

                        <RouterLink to="/ar/about-us">
                            عن الشركة
                        </RouterLink>
                    </li>

                    <li>
                        <span></span>

                        <RouterLink to="/ar/digital-assests">
                            الأصول الرقمية
                        </RouterLink>
                    </li>

                    <li>
                        <span></span>

                        <RouterLink to="/ar/advanced-services">
                        خدمات استشارية وتسويقية
                        </RouterLink>
                    </li>
    
    
                    <li>
                        <span></span>

                        <RouterLink to="/ar/contact-us">
                            تواصل معنا
                        </RouterLink>
                    </li>

                
    
    
    
                </ul>
            </div>

            <div className="down">
                <div className="contact-info">
                    <h3>
                        الموقع

                        <span>الرياض, الخبر</span>
                    </h3>
{/* 
                    <h3>
                        Partners

                        <span> <a href="#" target="_blank" aria-label="external link PlueRays Holdings"> PlueRays Holdings </a>
                            <a href="#" target="_blank" aria-label="external link Shaggy">Shaggy </a> 
                        </span>
                    </h3> */}


                    <h3>
                        التواصل الإجتماعي

                        <span>
                            <a rel="noopener noreferrer" target="_blank"  href="https://mobile.twitter.com/proeffect_sa" aria-label="instagram link">
                                <img src={TwLogo} aria-label="twitter-logo" />
                            </a>
                            <a rel="noopener noreferrer" target="_blank"   href="https://www.linkedin.com/company/proeffectsa" aria-label="facebook link">
                                <i className="lab la-linkedin-in"></i>
                            </a>
                            <a rel="noopener noreferrer" target="_blank"   href="https://www.instagram.com/proeffect_sa/" aria-label="twitter link">
                                <i className="lab la-instagram"></i>
                            </a>
                            <a  href="https://youtube.com/channel/UCJwsp9ypAg-lbJtzSrlyoig" aria-label="twitter link">
                                <i className="lab la-youtube"></i>
                            </a>
                            <a rel="noopener noreferrer" target="_blank" href="https://vimeo.com/proeffectmedia" aria-label="twitter link">
                                <i className="lab la-vimeo"></i>
                            </a>
                        </span>
                    </h3>


                    <h3>
                        الشروط

                        <span> سياسة الخصوصية </span>
                    </h3>

                </div>
            </div>
        </div>
    </div>





        </>
    )
}

export default Navbar;