import React from 'react';
import partner1 from './images/partners/1.png';
import partner2 from './images/partners/2.png';
import partner3 from './images/partners/3.png';
import partner4 from './images/partners/4.png';
import partner5 from './images/partners/5.png';
import partner6 from './images/partners/6.png';
import partner7 from './images/partners/7.png';
import partner8 from './images/partners/8.png';
import partner9 from './images/partners/9.png';
import partner10 from './images/partners/10.png';
import partner11 from './images/partners/11.png';
import partner12 from './images/partners/12.png';
import partner13 from './images/partners/13.png';
import partner14 from './images/partners/14.png';
import partner15 from './images/partners/15.png';
import partner16 from './images/partners/16.png';
import partner17 from './images/partners/17.png';
import partner18 from './images/partners/18.png';
import partner19 from './images/partners/19.png';
import partner20 from './images/partners/20.png';
import partner21 from './images/partners/21.png';
import partner22 from './images/partners/22.png';
import partner23 from './images/partners/23.png';
import partner24 from './images/partners/24.png';
import partner25 from './images/partners/25.png';
import partner26 from './images/partners/26.png';
// Continue importing the rest of your images

function OurClients() {
  return (
    <div className="our-clients">
        <div className="container">
            <div className="our-clients-content">
                
                <div className="body">
                    <div>
                    <div className="header">
                        <div>
                            <h2 data-aos-delay="100" data-aos="fade-in">OUR</h2>
                            <h2 data-aos-delay="200" data-aos="fade-in">CLIENTS</h2>
                        </div>
                        {/* <p data-aos-delay="300" data-aos="fade-in">
                            We take pride at Profect in a broad network of partnerships, whether from governmental entities or the private sector, including the Ministry of Energy and Saudi Aramco. These partnerships enhance our capabilities and expertise and allow us to reach a wider audience.
                        </p> */}
                    </div>

                    <h1 data-aos-delay="100" data-aos="fade-in">From them comes the inspiration, and to them goes the creativity. </h1>

                    </div>
                    <div className="clients">
                        <div className="clients-train">
                            {/* Repeat this pattern for each image */}
                            <img data-aos-delay="100" data-aos="fade-in" src={partner1} alt="partner logo" />
                            <img data-aos-delay="200" data-aos="fade-in" src={partner2} alt="partner logo" />
                            <img data-aos-delay="300" data-aos="fade-in" src={partner3} alt="partner logo" />
                            <img data-aos-delay="400" data-aos="fade-in" src={partner4} alt="partner logo" />
                            <img data-aos-delay="500" data-aos="fade-in" src={partner5} alt="partner logo" />
                            <img data-aos-delay="600" data-aos="fade-in" src={partner6} alt="partner logo" />
                            <img data-aos-delay="700" data-aos="fade-in" src={partner7} alt="partner logo" />
                            <img data-aos-delay="800" data-aos="fade-in" src={partner8} alt="partner logo" />
                            <img data-aos-delay="900" data-aos="fade-in" src={partner9} alt="partner logo" />



                            <img data-aos-delay="1000" data-aos="fade-in" src={partner10} alt="partner logo" />
                            <img data-aos-delay="1100" data-aos="fade-in" src={partner11} alt="partner logo" />
                            <img data-aos-delay="1200" data-aos="fade-in" src={partner12} alt="partner logo" />
                            <img data-aos-delay="1300" data-aos="fade-in" src={partner13} alt="partner logo" />
                            <img data-aos-delay="1400" data-aos="fade-in" src={partner14} alt="partner logo" />
                            <img data-aos-delay="1500" data-aos="fade-in" src={partner15} alt="partner logo" />
                            <img data-aos-delay="1600" data-aos="fade-in" src={partner16} alt="partner logo" />
                            <img data-aos-delay="1700" data-aos="fade-in" src={partner17} alt="partner logo" />
                            <img data-aos-delay="1800" data-aos="fade-in" src={partner18} alt="partner logo" />






                            <img data-aos-delay="1900" data-aos="fade-in" src={partner19} alt="partner logo" />
                            <img data-aos-delay="2000" data-aos="fade-in" src={partner20} alt="partner logo" />
                            <img data-aos-delay="2100" data-aos="fade-in" src={partner21} alt="partner logo" />
                            <img data-aos-delay="2200" data-aos="fade-in" src={partner22} alt="partner logo" />
                            <img data-aos-delay="2300" data-aos="fade-in" src={partner23} alt="partner logo" />
                            <img data-aos-delay="2400" data-aos="fade-in" src={partner24} alt="partner logo" />
                            <img data-aos-delay="2500" data-aos="fade-in" src={partner25} alt="partner logo" />
                            <img  data-aos-delay="2600" data-aos="fade-in" src={partner26} alt="partner logo" />
                        </div>


                        {/* Add more "clients-train" divs as needed, following the pattern above */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default OurClients;