import CompanyLogo from "./images/logos/1.png";
import { Link as RouterLink } from "react-router-dom";
import ProjectVideo from "./videos/landing-video.mp4";

import ProjectImage1 from "./images/projects/1.webp";
import ProjectImage2 from "./images/projects/2.webp";
import ProjectImage3 from "./images/projects/3.webp";
import ProjectImage4 from "./images/projects/4.webp";
import ProjectImage5 from "./images/projects/5.webp";

import { useEffect } from "react";





function CaseStudyPage(){

    useEffect(() => {
        const playButtons = document.querySelectorAll('.play-video');

        playButtons.forEach(button => {
            const video = button.previousElementSibling; // Assuming the video is always right before the button

            button.addEventListener('click', function() {
                if (video.paused) {
                    video.play();
                    button.style.display = 'none'; // Hide play button
                }
            });

            video.addEventListener('click', function() {
                if (!video.paused) {
                    video.pause();
                    button.style.display = ''; // Show play button
                }
            });
            video.addEventListener('ended', function() {
                button.style.display = ''; // Show play button when video ends
            });
        });
    }, []);


    return(
        <>



    <div className="case-study-header">
        <div className="container">
            <div className="header-content">
                <div>
                    <h1> NAJRAN | NATIONAL DAY </h1>
                    <h2> Media Distinction Award</h2>
                </div>

                <img src={CompanyLogo} alt="company logo"/>
            </div>

            
            <div className="project-video">
                <video src={ProjectVideo} alt="project-video" > </video>
                <button className="play-video" aria-label="play video button">
                    <i className="las la-play"></i>
                </button>
            </div>


            <div className="project-text">
                <div>
                    <h1>
                        OVERVIEW
                    </h1>

                    <h2> 
                        Designing temporary spaces
                        to blend in seamlessly with a
                        landmark site.
                    </h2>

                    <p>
                        LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND
                        TYPESETTING INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY’S
                    </p>
                </div>

                <p>
                    LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPESETTING INDUSTRY.
                    LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD DUMMY TEXT EVER SINCE THE 1500S,
                    WHEN AN UNKNOWN PRINTER TOOK A GALLEY LOREM IPSUM IS SIMPLY DUMMY TEXT OF
                    THE PRINTING AND TYPESETTING INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY’S
                    STANDARD DUMMY TEXT EVER SINCE THE 1500S, WHEN AN UNKNOWN
                </p>
            </div>



            <div className="project-images">
                <div className="image">
                    <img src={ProjectImage1} alt="project"/>
                </div>

                <div className="image">
                    <img src={ProjectImage2} alt="project"/>
                    <img src={ProjectImage3} alt="project"/>

                </div>

                <div className="image">
                    <img src={ProjectImage4} alt="project"/>
                    <img src={ProjectImage5} alt="project"/>

                </div>


            </div>



            <RouterLink to="/" aria-label="all-services"> ALL SERVICES <i className="las la-arrow-right"></i></RouterLink>
        </div>
    </div>







        
        </>
    )
}

export default CaseStudyPage;