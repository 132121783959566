

import ContactBack from "./images/contact/Rectangle-5.webp";
import Map1 from "./images/maps/khob.png";
import Map2 from "./images/maps/reyad.png";


function ContactUs(){

        // State to keep track of the currently active map
    // const [activeMap, setActiveMap] = useState('riyadh');

    // Function to change the active map
    // const showMap = (mapName) => {
    //     setActiveMap(mapName);
    // };



    return(
        <>
            
            <section className="contact-page-header">
                {/* <img className="overlay" src={overlayImage} alt="overlay"/> */}
                <img className="contact back" src={ContactBack} alt="contact back"/>
                <div className="container">
                    <div className="contact-page-header-content">
                        <h1> Proeffect, <br/>
                        Your <br></br> Creative Partner.</h1>

                        <h2>
                            <span></span>

                            At Proeffect, we believe in the power of teamwork and partnership
                            with those who share our passion for creativity and innovation. 
                            If you are interested in contributing to our story and leaving your mark in the world of creative production, we invite you to contact us and discover how we can achieve greatness together.

                            <i className="las la-arrow-down"></i>
                            <span></span>
                        </h2>



                        <span className="line"></span>
                    </div>
                </div>
            </section>





            <section className="contact-us">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 co-md-4 col-sm-12 col-12">
                            <div className="contact-us-content">
                                <div className="box">
                                    <h2>
                                        LETS
                                        EMBARK
                                        TOGETHER
                                    </h2>
                                </div>

                                <span></span>

                                <div className="contact-info">
                                    <div>
                                        <h2> Social Media </h2>
                                        <ul>
                                            <li>
                                                <a rel='noreferrer' target="_blank" href="https://mobile.twitter.com/proeffect_sa" aria-label="twitter external link">
                                                    Twitter
                                                </a>
                                            </li>

                                            <li>
                                                <a rel='noreferrer' target="_blank" href="https://www.linkedin.com/company/proeffectsa" aria-label="linkedin external link">
                                                    LinkedIn
                                                </a>
                                            </li>

                                            <li>
                                                <a rel='noreferrer' target="_blank" href="https://www.instagram.com/proeffect_sa/" aria-label="instagram external link">
                                                    Instagram
                                                </a>
                                            </li>

                                            
                                            <li>
                                                <a rel='noreferrer' target="_blank" href="https://youtube.com/channel/UCJwsp9ypAg-lbJtzSrlyoig" aria-label="youtube external link">
                                                    Youtube
                                                </a>
                                            </li>


                                            
                                            <li>
                                                <a rel='noreferrer' target="_blank" href="https://vimeo.com/proeffectmedia" aria-label="vimeo external link">
                                                    Vimeo
                                                </a>
                                            </li>

                                        </ul>
                                    </div>





                                    <div>
                                        <h2> Contact </h2>
                                        <ul>
                                            <li>
                                                <a rel='noreferrer' target="_blank" href="mailto:info@proeffect.sa" aria-label="email external link">
                                                    info@proeffect.sa
                                                </a>
                                            </li>

                                            <li>
                                                <a  rel='noreferrer' target="_blank" href="mailto:jobs@proeffect.sa" aria-label="job email external link">
                                                    For Jobs : jobs@proeffect.sa
                                                </a>
                                            </li>



                                            <li>
                                                <a rel='noreferrer' target="_blank" href="tel:+966138844144" aria-label="Instagram external link">
                                                    +966 138 844 144
                                                </a>
                                            </li>

                                        </ul>
                                    </div>


                                    <div>
                                        <h2> Address </h2>
                                        <ul>
                                            <li>
                                                <h3>
                                                    Riyadh
                                                    <span> 3620 طريق الملك عبدالله الفرعي , الملك 6414</span>
                                                </h3>
                                            </li>

                                            <li>
                                                <h3>
                                                    Al Khobar
                                                    <span> 208 شارع 21 العليا الخبر ,  34447  </span>
                                                </h3>
                                            </li>

                                        </ul>
                                    </div>



                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 co-md-8 col-sm-12 col-12">
                            <div className="form">
                                <form action="">
                                    <div className="input">
                                        <label>  Your Name </label>
                                        <input type="text" placeholder="" required/>
                                    </div>
            
                                    <div className="input">
                                        <label>  Your Email </label>
                                        <input type="email" placeholder="" required/>
                                    </div>
            
                                    
                                    <div className="input">
                                        <label>  Your Phone </label>
                                        <input type="tel" placeholder="" required/>
                                    </div>
            
                                    
                                    
                                    <div className="input">
                                        <label>  What Are You Looking For? </label>
                                        <select required>
                                            <option value="TVC"> TVC </option>
                                            <option value="Video clips"> Video clips </option>
                                            <option value="Documantaries"> Documantaries </option>
                                            <option value="Tell your Story"> Tell your Story</option>
                                            <option value="2D & 3D Animation"> 2D & 3D Animation </option>
                                            <option value="TV Services"> TV Services </option>
                                            <option value="Event Highlights">Event Highlights </option>
                                            <option value="BTS"> BTS </option>
                                            <option value="Archiving and cloud storage for business and artistic productions."> Archiving and cloud storage for business and artistic productions. </option>
                                            <option value="The auditory identity.">The auditory identity.</option> 

                                        </select>
                                    </div>
            
            
                                    <div className="input">
                                        <label>  Your message </label>
                                        <textarea required placeholder=""></textarea>
                                    </div>
            
            
                                    <button type="submit">submit</button> 
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="map-section">
                <div className="container">
                    <div className="map-content">
                    <div className="maps">
                        <div id="map-riyadh" className="active">
                            <img src={Map2} alt="map riyadh" />
                            <span> </span>
                        </div>
                
                    </div>

                    <div className="buttons">
                        <a rel='noreferrer' target='_blank' href="https://maps.app.goo.gl/4Vsc25oZnDc7cNNo8?g_st=iwb" >Riyadh</a>
                        <a rel='noreferrer' target='_blank' href="https://www.google.com/maps/place/Proeffect/@26.3022654,50.1770251,15z/data=!4m5!3m4!1s0x0:0x4b0d6c89087b20ba!8m2!3d26.3022654!4d50.1770251" >Al Khobar</a>
                    </div>
                    </div>
                </div>
                </section>












        </>
    )
}

export default ContactUs;