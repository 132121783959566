import React, { useEffect   } from 'react';
import $ from 'jquery';

import { Link as RouterLink } from 'react-router-dom';
import TVCVideo from './showrealsservices/TVC 20 S V-4.mp4';
import VIDEOCLIPSVideo from './showrealsservices/Video Clip 30s V-4.mp4';
import DOCUMANTARIESVideo from './showrealsservices/Documentaries 30 S V-03.mp4';
import TELLYOURSTORYVideo from './showrealsservices/Tell your Story 30 S V-4.mp4';
import ANIMATIONVideo from './showrealsservices/20 S 2D - 3D Animation- V- 3.mp4';
import TVSERVICESVideo from './showrealsservices/TV Services V1.mp4';
import EVENTHIGHLIGHTSVideo from './showrealsservices/Event Highlights- 30 S V-3.mp4';
import BTSVideo from './showrealsservices/BTS 20 S V-3.mp4';
import AUDITORYVideo from './showrealsservices/auditory.mp4';
import CLOUDSTORAGEARCHIVINGVideo from './showrealsservices/storage.mp4';


function Services(){

    useEffect(() => {
        const playButtons = document.querySelectorAll('.play-video');

        playButtons.forEach(button => {
            const video = button.previousElementSibling; // Assuming the video is always right before the button

            button.addEventListener('click', function() {
                if (video.paused) {
                    video.play();
                    button.style.display = 'none'; // Hide play button
                }
            });

            video.addEventListener('click', function() {
                if (!video.paused) {
                    video.pause();
                    button.style.display = ''; // Show play button
                }
            });
            video.addEventListener('ended', function() {
                button.style.display = ''; // Show play button when video ends
            });
        });
    }, []);

    useEffect(() => {
        // Unbind existing click events to prevent multiple bindings
            $('.our-services .service .service-head').off('click').on('click', function() {
                $(this).next().slideToggle();
                
                // Find the <i> element within the clicked .service-head
                var $icon = $(this).find("i");
                
                // Check if the icon currently has the 'la-plus' className
                if ($icon.hasClass("la-plus")) {
                    $icon.removeClass("la-plus").addClass("la-minus");
                } else {
                    $icon.removeClass("la-minus").addClass("la-plus");
                }
            });
    }, []);

    return(
        
            <div id="services" className="our-services">
            <div className="container">
                <div className="our-services-content">
                    <div className="header">
                        <h1 data-aos-delay="100" data-aos="fade-in">OUR </h1>
                        <h1 data-aos-delay="200" data-aos="fade-in">SERVICES</h1>
                    </div>

                    <div data-aos-delay="100" data-aos="fade-in" className="service">
                        <div className="service-head">
                            <h1> TVC </h1>
                            <p>
                                We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.
                            </p>

                            <i className="las la-plus"></i>
                        </div>

                        <div className="service-body">
                            <div className="service-project">
                                <div className="project-left">
                                    <h1> TVC </h1>

                                    <div className="info">
                                        <div>
                                            <RouterLink to="/work?category=tvc" aria-label="view project">
                                                SEE PROJECTS
                                            </RouterLink>

                                            <RouterLink to="/work?category=tvc" aria-label="view-all-projects">
                                                <i className="las la-plus"></i>
                                            </RouterLink>
                                        </div>

                                        <p>
                                            We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.
                                        </p>
                                    </div>
                                </div>

                                <div className="project-right">

                                <div className="project-video">
                                    <video  src={TVCVideo} playsInline autoPlay loop muted alt="project-video" > </video>
                                    {/* <button className="play-video" aria-label="play video button">
                                        <i className="las la-play"></i>
                                    </button> */}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    
                    
                    <div data-aos-delay="100" data-aos="fade-in" className="service">
                        <div className="service-head">
                            <h1> Video clips

                            </h1>
                            <p>
                                We translate your emotions into melodies, words, and shots that touch hearts and express your message in a captivating manner.

                            </p>

                            <i className="las la-plus"></i>
                        </div>

                        <div className="service-body">
                            <div className="service-project">
                                <div className="project-left">
                                    <h1> Video clips </h1>

                                    <div className="info">
                                        <div>
                                        <RouterLink to="/work?category=videoclips" aria-label="view project">
                                                SEE PROJECTS
                                            </RouterLink>

                                            <RouterLink to="/work?category=videoclips" aria-label="view-all-projects">
                                                <i className="las la-plus"></i>
                                            </RouterLink>
                                        </div>

                                        <p>
                                            
                                            We translate your emotions into melodies, words, and shots that touch hearts and express your message in a captivating manner.
                                        </p>
                                    </div>
                                </div>

                                <div className="project-right">
                                    

                                <div className="project-video">
                                    <video  src={VIDEOCLIPSVideo} playsInline autoPlay loop muted alt="project-video" > </video>
                                    {/* <button className="play-video" aria-label="play video button">
                                        <i className="las la-play"></i>
                                    </button> */}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    
                    <div data-aos-delay="100" data-aos="fade-in" className="service">
                        <div className="service-head">
                            <h1> Documentaries  </h1>
                            <p>
                            We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.
                                </p>

                                <i className="las la-plus"></i>
                        </div>

                        <div className="service-body">
                            <div className="service-project">
                                <div className="project-left">
                                    <h1> Documantaries  </h1>

                                    <div className="info">
                                        <div>
                                            <RouterLink to="/work?category=documantaries" aria-label="view project">
                                                SEE PROJECTS
                                            </RouterLink>

                                            <RouterLink to="/work?category=documantaries" aria-label="view-all-projects">
                                                <i className="las la-plus"></i>
                                            </RouterLink>
                                        </div>

                                        <p>
                                        We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.
                                        </p>
                                    </div>
                                </div>

                                <div className="project-right">
                                

                                <div className="project-video">
                                    <video  src={DOCUMANTARIESVideo} playsInline autoPlay loop muted alt="project-video" > </video>
                                    {/* <button className="play-video" aria-label="play video button">
                                        <i className="las la-play"></i>
                                    </button> */} 
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div data-aos-delay="100" data-aos="fade-in" className="service">
                        <div className="service-head">
                            <h1> Tell your Story 

                            </h1>
                            <p>
                                We transform your stories into impactful narratives that touch emotions and immortalize precious moments.
                            </p>

                            <i className="las la-plus"></i>
                        </div>

                        <div className="service-body">
                            <div className="service-project">
                                <div className="project-left">
                                    <h1>Tell your Story 

                                    </h1>

                                    <div className="info">
                                        <div>
                                        <RouterLink to="/work?category=tellyourstory " aria-label="view project">
                                                SEE PROJECTS
                                            </RouterLink>

                                            <RouterLink to="/work?category=tellyourstory" aria-label="view-all-projects">
                                                <i className="las la-plus"></i>
                                            </RouterLink>
                                        </div>

                                        <p>
                                        We transform your stories into impactful narratives that touch emotions and immortalize precious moments.
                                        </p>
                                    </div>
                                </div>

                                <div className="project-right">

                                <div className="project-video">
                                    <video  src={TELLYOURSTORYVideo} playsInline autoPlay loop muted alt="project-video" > </video>
                                    {/* <button className="play-video" aria-label="play video button">
                                        <i className="las la-play"></i>
                                    </button> */}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div data-aos-delay="100" data-aos="fade-in" className="service">
                        <div className="service-head">
                            <h1>2D & 3D animation

                            </h1>
                            <p>We bring your visions to life with captivating 2D & 3D animations </p>

                            <i className="las la-plus"></i>
                        </div>

                        <div className="service-body">
                            <div className="service-project">
                                <div className="project-left">
                                    <h1> 2D & 3D Animation

                                    </h1>

                                    <div className="info">
                                        <div>
                                        <RouterLink to="/work?category=animation " aria-label="view project">
                                                SEE PROJECTS
                                            </RouterLink>

                                            <RouterLink to="/work?category=animation" aria-label="view-all-projects">
                                                <i className="las la-plus"></i>
                                            </RouterLink>

                                        </div>

                                        <p>
                                        We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.
                                        </p>
                                    </div>
                                </div>

                                <div className="project-right">

                                <div className="project-video">
                                    <video  src={ANIMATIONVideo} playsInline autoPlay loop muted alt="project-video" > </video>
                                    {/* <button className="play-video" aria-label="play video button">
                                        <i className="las la-play"></i>
                                    </button> */}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div data-aos-delay="100" data-aos="fade-in" className="service">
                        <div className="service-head">
                            <h1> TV Services

                            </h1>
                            <p>We provide comprehensive TV Services, delivering high-quality broadcasting solutions  </p>

                            <i className="las la-plus"></i>
                        </div>

                        <div className="service-body">
                            <div className="service-project">
                                <div className="project-left">
                                    <h1> TV Services

                                    </h1>

                                    <div className="info">
                                        <div>
                                            <RouterLink to="/work?category=tvservices " aria-label="view project">
                                                SEE PROJECTS
                                            </RouterLink>

                                            <RouterLink to="/work?category=tvservices" aria-label="view-all-projects">
                                                <i className="las la-plus"></i>
                                            </RouterLink>
                                        </div>

                                        <p>
                                        We provide comprehensive TV Services, delivering high-quality broadcasting solutions that ensure your content reaches audiences with clarity, impact, and unparalleled professionalism.
                                        </p>
                                    </div>
                                </div>

                                <div className="project-right">

                                <div className="project-video">
                                    <video  src={TVSERVICESVideo} playsInline autoPlay loop muted alt="project-video" > </video>
                                    {/* <button className="play-video" aria-label="play video button">
                                        <i className="las la-play"></i>
                                    </button> */}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div data-aos-delay="100" data-aos="fade-in" className="service">
                        <div className="service-head">
                            <h1> Event Highlights </h1>
                            <p>
                            We transform your events into unforgettable experiences and document them with precision and creativity to live on in memory.
                            </p>

                            <i className="las la-plus"></i>
                        </div>

                        <div className="service-body">
                            <div className="service-project">
                                <div className="project-left">
                                    <h1> Event Highlights </h1>

                                    <div className="info">
                                        <div>
                                            <RouterLink to="/work?category=eventhighlights " aria-label="view project">
                                                SEE PROJECTS
                                            </RouterLink>

                                            <RouterLink to="/work?category=eventhighlights" aria-label="view-all-projects">
                                                <i className="las la-plus"></i>
                                            </RouterLink>
                                        </div>

                                        <p>
                                        We transform your events into unforgettable experiences and document them with precision and creativity to live on in memory.
                                        </p>
                                    </div>
                                </div>

                                <div className="project-right">
                            <div className="project-video">
                                    <video  src={EVENTHIGHLIGHTSVideo} playsInline autoPlay loop muted alt="project-video" > </video>
                                    {/* <button className="play-video" aria-label="play video button">
                                        <i className="las la-play"></i>
                                    </button> */}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div data-aos-delay="100" data-aos="fade-in" className="service">
                        <div className="service-head">
                            <h1> BTS </h1>
                            <p> We capture the essence of Behind The Scenes (BTS), offering a unique glimpse into the creative process</p>

                            <i className="las la-plus"></i>
                        </div>

                        <div className="service-body">
                            <div className="service-project">
                                <div className="project-left">
                                    <h1> BTS </h1>

                                    <div className="info">
                                        <div>
                                            <RouterLink to="/work?category=bts " aria-label="view project">
                                                SEE PROJECTS
                                            </RouterLink>

                                            <RouterLink to="/work?category=bts" aria-label="view-all-projects">
                                                <i className="las la-plus"></i>
                                            </RouterLink>
                                        </div>

                                        <p>
                                        We capture the essence of Behind The Scenes (BTS), offering a unique glimpse into the creative process and showcasing the hard work and dedication that goes into making every project a masterpiece.
                                        </p>
                                    </div>
                                </div>

                                <div className="project-right">

                                <div className="project-video">
                                    <video  src={BTSVideo} playsInline autoPlay loop muted alt="project-video" > </video>
                                    {/* <button className="play-video" aria-label="play video button">
                                        <i className="las la-play"></i>
                                    </button> */}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div data-aos-delay="100" data-aos="fade-in" className="service">
                        <div className="service-head">
                            <h1> Designing auditory identities </h1>
                            <p> We design a distinctive auditory identity that enhances your brand and sets you apart from competitors.</p>

                            <i className="las la-plus"></i>
                        </div>

                        <div className="service-body">
                            <div className="service-project">
                                <div className="project-left">
                                    <h1> Designing auditory identities </h1>

                                    <div className="info">
                                        <div>
                                            {/* <a href="#" aria-label="view project">
                                                SEE PROJECTS
                                            </a>

                                            <a href="#" aria-label="view-all-projects">
                                                <i className="las la-plus"></i>
                                            </a> */}
                                        </div>

                                        <p>
                                        We design a distinctive auditory identity that enhances your brand and sets you apart from competitors.
                                        </p>
                                    </div>
                                </div>

                                <div className="project-right">

                                <div className="project-video">
                                    <video  src={AUDITORYVideo} playsInline autoPlay loop muted alt="project-video" > </video>
                                    {/* <button className="play-video" aria-label="play video button">
                                        <i className="las la-play"></i>
                                    </button> */}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    
                    <div data-aos-delay="100" data-aos="fade-in" className="service">
                        <div className="service-head">
                            <h1> Cloud Storage Archiving</h1>
                            <p>Sustainable storage in the cloud world preserves your dreams and projects in one place.</p>

                            <i className="las la-plus"></i>
                        </div>

                        <div className="service-body">
                            <div className="service-project">
                                <div className="project-left">
                                    <h1> Cloud Storage Archiving </h1>

                                    <div className="info">
                                        <div>
                                            {/* <a href="#" aria-label="view project">
                                                SEE PROJECTS
                                            </a>

                                            <a href="#" aria-label="view-all-projects">
                                                <i className="las la-plus"></i>
                                            </a> */}
                                        </div>

                                        <p>
                                        Sustainable storage in the cloud world preserves your dreams and projects in one place.
                                        </p>
                                        
                                    </div>
                                </div>

                                <div className="project-right">

                                <div className="project-video">
                                    <video  src={CLOUDSTORAGEARCHIVINGVideo} playsInline autoPlay loop muted alt="project-video" > </video>
                                    {/* <button className="play-video" aria-label="play video button">
                                        <i className="las la-play"></i>
                                    </button> */}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>








                </div>
            </div>
        </div>


    )
}


export default Services;