import LandingVid from '../videos/landing-video.mp4';

function Landing(){
    return(
        <>
        <div className="landing ar">
                <div className="landing-content">
                    <video src={LandingVid} alt="video main" autoPlay muted loop playsInline> </video>
                </div>
            </div>
        </>
    )
}

export default Landing;