

import OverlayImage from "../images/Rectangle -1.webp";
import Oletter from "../images/objects/goals.png";

import AdvImg from "../images/advsearv/1.webp";


function AdvancedServices(){
    return(
        <>
            <section className="advanced-services  ar">
                <div className="container">
                    <div className="advanced-header">
                        <img className="overlay" src={OverlayImage} alt="overlay"/>
                        <div>
                            <p ata-aos-delay="100" data-aos="fade-in"> <strong>نسعى </strong>   لتقديم تجارب فريدة وغامرة تتجاوز توقعات عملائنا وترتقي بالمحتوى الإبداعي إلى مستويات جديدة من الابتكار والتأثير </p>
                            <span ata-aos-delay="200" data-aos="fade-in"> </span>
                        </div>

                        {/* <h1 ata-aos-delay="300" data-aos="fade-in"> PARTNERS </h1> */}

                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="adv-ser-content">
                                <div className="adv-serv">
                                    <h1 ata-aos-delay="100" data-aos="fade-in"> بلوريز القابضة  </h1>
                                    <p ata-aos-delay="200" data-aos="fade-in">
                                    ‍شركة قابضة في مدينة الرياض على أيدي خُبراء أكفاء من مجالاتٍ عدة ، نمت الفكرة مع نمو الفرص والتحوّل الاقتصادي التي شرعت به المملكة العربية السعودية في تحقيق رؤيتها ٢٠٣٠ بقيادة شبابها الطموح ومن خلاصة خبرتنا في بلورايز نُمهد لك فرصة الريادة بخدماتٍ مميزة نبني بها مستقبلًا مشرقًا لمشروعك.
                                    </p>

                                    <ul>
                                        <li ata-aos-delay="100" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            تأسيس المشاريع الريادية 
                                        </li>

                                        <li ata-aos-delay="200" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            صناعة العلامات التجارية 
                                        </li>

                                        <li ata-aos-delay="300" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            الاستشارات وتطوير الاعمال
                                        </li>

                                        <li ata-aos-delay="400" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            رسم خطط استراتيجية
                                        </li>

                                        <li ata-aos-delay="500" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            دراسة ابحاث السوق
                                        </li>
                                    </ul>
                                </div>

                                <div className="adv-serv">
                                    <h1 ata-aos-delay="100" data-aos="fade-in"> شغف: </h1>
                                    <p ata-aos-delay="200" data-aos="fade-in">
                                    مجموعة تسويقية سعودية متخصصة في تقديم الخدمات الاستشارية والتسويقية وتصميم وتنفيذ الحملات الاتصالية بروح محلية وثقافة اصيلة موائمة لتوجهات رؤية المملكة 2030 الطموحة، تقدم مخرجاتها بطرق مبتكرة وابداعية تلهم الحواس وتحقق الاثر اللازم للعملاء وشركاء النجاح
                                    </p>

                                    <ul>
                                        <li ata-aos-delay="100" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            الاستراتيجيات الاتصالية 
                                        </li>

                                        <li ata-aos-delay="200" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            التسويق الرقمي 
                                        </li>

                                        <li ata-aos-delay="300" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            كتابة وصناعة المحتوى الإبداعي 
                                        </li>

                                        <li ata-aos-delay="400" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            بناء الهويات والتصاميم
                                        </li>

                                        <li ata-aos-delay="500" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            ادارة حسابات التواصل الاجتماعي 
                                        </li>


                                        <li ata-aos-delay="600" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            الابحاث السوقية وتحليل البيانات الرقمية
                                        </li>

                                        <li ata-aos-delay="700" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            الخدمات الاستشارية
                                        </li>
                                    </ul>
                                </div>


                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="adv-serv-img">
                                <img ata-aos-delay="200" data-aos="fade-in" src={AdvImg} alt="advanced search content" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AdvancedServices;