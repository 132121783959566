import FooterImg1 from "./images/Rectangle -1.webp";
import Logo from './images/logo/logo.png';
import { Link as RouterLink } from "react-router-dom";

import TwLogo from './images/footer/twitter.png';

function Footer(){
    return(
        <>
            
    <footer>
        <img src={FooterImg1} className="overlay" alt="overlay" />
        <div className="container">
                <div className="footer-contact">


                    <h1 data-aos-delay="100" data-aos="fade-in">  lets Work together  <RouterLink to="/contact-us" aria-label="contact us link"> <i className="las la-arrow-right"></i> </RouterLink></h1>

                    <div className="socials">
                        <a rel="noopener noreferrer" target="_blank" data-aos-delay="200" data-aos="fade-in" href="https://mobile.twitter.com/proeffect_sa" aria-label="instagram link">
                            <img src={TwLogo} aria-label="twitter-logo" />
                        </a>
                        <a rel="noopener noreferrer" target="_blank" data-aos-delay="300" data-aos="fade-in" href="https://www.linkedin.com/company/proeffectsa" aria-label="facebook link">
                            <i className="lab la-linkedin-in"></i>
                        </a>
                        <a rel="noopener noreferrer" target="_blank" data-aos-delay="400" data-aos="fade-in" href="https://www.instagram.com/proeffect_sa/" aria-label="twitter link">
                            <i className="lab la-instagram"></i>
                        </a>
                        <a data-aos-delay="500" data-aos="fade-in" href="https://youtube.com/channel/UCJwsp9ypAg-lbJtzSrlyoig" aria-label="twitter link">
                            <i className="lab la-youtube"></i>
                        </a>
                        <a rel="noopener noreferrer" target="_blank" data-aos-delay="600" data-aos="fade-in" href="https://vimeo.com/proeffectmedia" aria-label="twitter link">
                            <i className="lab la-vimeo"></i>
                        </a>
                    </div>


                </div>

                <div className="footer-content">
                    <div className="footer-logo">
                        <RouterLink to="/">
                            <img src={Logo} alt="proeffect logo"/>
                        </RouterLink>
                    </div>

                    <h5 > 2024 &copy; , All rights reserved <span>Proeffect Production </span> </h5>



                    {/* <div className="footer-short-links">
                        <h1> Short Links </h1>
                        <ul>
                            <li>
                                <a href="#" aria-label="page link">
                                    centuries
                                </a>
                            </li>
                            <li>
                                <a href="#" aria-label="page link">
                                    electronic
                                </a>
                            </li>
                            <li>
                                <a href="#" aria-label="page link">
                                    typesetting
                                </a>
                            </li>
                            <li>
                                <a href="#" aria-label="page link">
                                    remaining
                                </a>
                            </li>

                            <li>
                                <a href="#" aria-label="page link">
                                    essentially
                                </a>
                            </li>

                            <li>
                                <a href="#" aria-label="page link">
                                    unchanged
                                </a>
                            </li>


                        </ul>
                    </div>







                    <div className="footer-short-links">
                        <h1> Our Services </h1>
                        <ul>
                            <li>
                                <a href="#" aria-label="page link">
                                    centuries
                                </a>
                            </li>
                            <li>
                                <a href="#" aria-label="page link">
                                    electronic
                                </a>
                            </li>
                            <li>
                                <a href="#" aria-label="page link">
                                    typesetting
                                </a>
                            </li>
                            <li>
                                <a href="#" aria-label="page link">
                                    remaining
                                </a>
                            </li>

                            <li>
                                <a href="#" aria-label="page link">
                                    essentially
                                </a>
                            </li>

                            <li>
                                <a href="#" aria-label="page link">
                                    unchanged
                                </a>
                            </li>


                        </ul>
                    </div>









                    <div className="footer-short-links">
                        <h1> Example dfghfg </h1>
                        <ul>
                            <li>
                                <a href="#" aria-label="page link">
                                    centuries
                                </a>
                            </li>
                            <li>
                                <a href="#" aria-label="page link">
                                    electronic
                                </a>
                            </li>

                            <li>
                                <a href="#" aria-label="page link">
                                    centuries
                                </a>
                            </li>
                            <li>
                                <a href="#" aria-label="page link">
                                    electronic
                                </a>
                            </li>

                        </ul>
                    </div> */}
                </div>


        </div>
    </footer>



        </>
    )
}

export default Footer;