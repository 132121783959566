import React, { useEffect , useState, useRef  } from 'react';
import $ from 'jquery';

import { Link as RouterLink } from 'react-router-dom';
import TVCVideo from '../showrealsservices/TVC 20 S V-4.mp4';
import VIDEOCLIPSVideo from '../showrealsservices/Video Clip 30s V-4.mp4';
import DOCUMANTARIESVideo from '../showrealsservices/Documentaries 30 S V-03.mp4';
import TELLYOURSTORYVideo from '../showrealsservices/Tell your Story 30 S V-4.mp4';
import ANIMATIONVideo from '../showrealsservices/20 S 2D - 3D Animation- V- 3.mp4';
import TVSERVICESVideo from '../showrealsservices/TV Services V1.mp4';
import EVENTHIGHLIGHTSVideo from '../showrealsservices/Event Highlights- 30 S V-3.mp4';
import BTSVideo from '../showrealsservices/BTS 20 S V-3.mp4';
import AUDITORYVideo from '../showrealsservices/auditory.mp4';
import CLOUDSTORAGEARCHIVINGVideo from '../showrealsservices/storage.mp4';


function NewServices(){

    const videos = [
        {
            src: TVCVideo,
            title: 'الإنتاج الإعلاني ',
            description: 'نبدع في صياغة الرسائل وتقديمـــهـــا بأسلـــــــــوب فريـــد يـــلـــفـــت الأنـــظار لتحقيـــــــق الأهـــــــــداف.',
            category: 'tvc'
        },
        {
            src: VIDEOCLIPSVideo,
            title: ' صناعة الفيديو كليب',
            description: 'نترجم مشاعركم إلى نغمات كلمـــــات ولقطـــات تلامس القلــــوب عبر عـــن رسالتكـم بأسلـــوب ملفــــــت.',
            category: 'videoclips'
        },
        {
            src: DOCUMANTARIESVideo,
            title: 'إنتاج الوثائقيات ',
            description: 'نروي قصصكم بأسلوب يخلد إنجازاتكم بشكل احترافي ويلهم الأجيال القادمة',
            category: 'documentaries'
        },
        {
            src: TELLYOURSTORYVideo,
            title: 'توثيق القصص',
            description: 'نحول قصصكم إالى حكايات مؤثرة تلامس المشاعر وتخلد اللحظات الثمينة.',
            category: 'storytelling'
        },
        {
            src: ANIMATIONVideo,
            title: 'الرسوم المتحركة ثنائية وثلاثية الأبعاد',
            description: 'نحن نجسد رؤاكم إلى واقع مع رسوم متحركة ثنائية وثلاثية الأبعاد جذابة.',
            category: 'animation'
        },
        {
            src: TVSERVICESVideo,
            title: 'خدمة التلفزيون',
            description: 'نحن نقدم خدمات تلفزيونية شاملة، نوفر حلول بث عالية الجودة.',
            category: 'tv'
        },
        {
            src: EVENTHIGHLIGHTSVideo,
            title: 'تغطية الفعاليات ',
            description: ' نحول فعاليتكم إلى تجــــارب لا تـــــنســــــــى، ونوثقها بدقــــة وإبـــداع لتعيش فــــــــي الذاكرة.',
            category: 'events'
        },
        {
            src: BTSVideo,
            title: 'BTS',
            description: 'نحن نلتقط جوهر الكواليس (BTS)، نقدم نظرة فريدة إلى عملية الإبداع. ',
            category: 'bts'
        },
        {
            src: AUDITORYVideo,
            title: 'تصميم الهوية السمعية ',
            description: 'نصمم هوية صوتية مميزة تعزز علامتكم ميزكم عن التجارية وت  المنافسين.',
            category: 'audio'
        },
        {
            src: CLOUDSTORAGEARCHIVINGVideo,
            title: ' الأرشفة والتخزين السحابي ',
            description: 'تخزين مستدام في عالم سحابي يحفظ أحلامك ومشاريعك في مكان واحد ',
            category: 'storage'
        }
    ];

    const videoRefs = useRef(videos.map(() => React.createRef()));
    const [playing, setPlaying] = useState({});
    const [userInteracted, setUserInteracted] = useState({});
    
    const handleMouseEnter = (index) => {
        if (videoRefs.current[index].current && !playing[index] && userInteracted[index]) {
            videoRefs.current[index].current.play();
            setPlaying(prev => ({ ...prev, [index]: true }));
        }
    };
    
    const handleMouseLeave = (index) => {
        if (videoRefs.current[index].current && playing[index]) {
            videoRefs.current[index].current.pause();
            setPlaying(prev => ({ ...prev, [index]: false }));
        }
    };
    
    const handleClick = (index) => {
        if (videoRefs.current[index].current) {
            setUserInteracted(prev => ({ ...prev, [index]: true }));
            if (playing[index]) {
                videoRefs.current[index].current.pause();
            } else {
                videoRefs.current[index].current.play();
            }
            setPlaying(prev => ({ ...prev, [index]: !prev[index] }));
        }
    };


    return(
        <>
            <div id="services" className="new-our-services ar">
                <div className='container'>
                    <div className="our-services-content">
                        <div className="header">
                            <h1 data-aos-delay="100" data-aos="fade-in">بروإفكت </h1>
                            <h1 data-aos-delay="200" data-aos="fade-in">خدماتنا</h1>
                        </div>

                        <div className='row'>

                        {videos.map((video, index) => (
                            <div key={index} className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='service'>
                                <video
                                        ref={videoRefs.current[index]}
                                        src={video.src}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={() => handleMouseLeave(index)}
                                        onClick={() => handleClick(index)}
                                        loop
                                        
                                        controls={false}
                                    ></video>
                                    {!playing[index] && (
                                        <button className='play-video' onClick={() => handleClick(index)}>
                                            <i className="las la-play"></i>
                                        </button>
                                    )}
                                    <div className='description'>
                                        <h1>{video.title}</h1>
                                        <p>{video.description}</p>
                                        <div>
                                            <RouterLink to={`/ar/work?category=${video.category}`} aria-label="view project">
                                            مشاهدة المشاريع
                                            </RouterLink>
                                            <RouterLink to={`/ar/work?category=${video.category}`} aria-label="view-all-projects">
                                                <i className="las la-plus"></i>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}


                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}


export default NewServices;