import OverlayImage from "./images/Rectangle -1.webp";
import Arrow from "./images/icons/arrow-10.png";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';



import TVCVideo from './showrealsservices/TVC 20 S V-4.mp4';
import VIDEOCLIPSVideo from './showrealsservices/Video Clip 30s V-4.mp4';
import DOCUMANTARIESVideo from './showrealsservices/Documentaries 30 S V-03.mp4';
import TELLYOURSTORYVideo from './showrealsservices/Tell your Story 30 S V-4.mp4';
import ANIMATIONVideo from './showrealsservices/20 S 2D - 3D Animation- V- 3.mp4';
import TVSERVICESVideo from './showrealsservices/TV Services V1.mp4';
import EVENTHIGHLIGHTSVideo from './showrealsservices/Event Highlights- 30 S V-3.mp4';
import BTSVideo from './showrealsservices/BTS 20 S V-3.mp4';
import AudioVideo from './showrealsservices/auditory.mp4';
import StorageVideo from './showrealsservices/storage.mp4';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function WorkPage(){


    const ProjectsData = [
        {
        id: '1',
        category: 'tvc',
        catText : ' TVC ',
        catdesc : 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
        catshowreal  : TVCVideo,
        header: ' Global Corridor Artificial Intelligence ',
        description: [
            " Embarking on an engaging journey through AI Corridor, we joined three developers from different locations with a common goal of collaborating on an artificial intelligence project."
        ],
        vimeolink: [
            "https://player.vimeo.com/video/893021260?h=d911aef641&title=0&byline=0&portrait=0" ,
        ]
        },

        {
            id: '2',
            category: 'tvc',
            catText : ' TVC ',
        catdesc : 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
        catshowreal  : TVCVideo,
            header: ' LAB7 TVC ',
            description: [
                " We wrote and produced the promotional video for LAB7. Khalid's head can hold millions of unique ideas, but he didn't know the right place to realize them, until he joined LAB7."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898878950?h=c1c39537b9&title=0&byline=0&portrait=0",
            ]
        },

        {
            id: '3',
            category: 'tvc',
        catText : ' TVC ',
        catdesc : 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
        catshowreal  : TVCVideo,
            header: ' Islamic Military Counter Terrorism Coalition || Dangerous Curiosity',
            description: [
                " Faisal, a student in middle school, is led by his curiosity on the internet to a dangerous place whose consequences he did not anticipate.A part of the 'Make a Difference' campaign that we wrote and produced, launched in collaboration with the Islamic Military Counter Terrorism Coalition."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898886244?h=ae58db69df&title=0&byline=0&portrait=0" ,
            ]
        },

        
        {
            id: '4',
            category: 'tvc',
        catText : ' TVC ',
        catdesc : 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
        catshowreal  : TVCVideo,
            header: ' Islamic Military Counter Terrorism Coalition- Identity Theft',
            description: [
                " Mohammed, a young man, falls victim to identity theft, and we follow his journey as he struggles to set things right. However, some challenges stand in his way A part of the 'Make a Difference' campaign that we wrote and produced, launched in collaboration with the Islamic Military Counter Terrorism Coalition."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898883246?h=98883dc8c2&title=0&byline=0&portrait=0" ,
            ]
        },


        
        {
            id: '5',
            category: 'tvc',
        catText : ' TVC ',
        catdesc : 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
        catshowreal  : TVCVideo,
            header: ' F1 School ',
            description: [
                " We scripted and produced this piece for the Formula 1 in Schools program, aimed at empowering youth in the fields of science, technology, engineering, and mathematics in the future. We follow the story of students navigating through various stages to create a Formula F1 car."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893019085?h=57a2ab7ba9&title=0&byline=0&portrait=0" ,
            ]
        },


        
        
        {
            id: '6',
            category: 'tvc',
        catText : ' TVC ',
        catdesc : 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
        catshowreal  : TVCVideo,
            header: ' SWCC Saudi National Day 90  ',
            description: [
                "On National Day, we showcase the remarkable success of the Saline Water Conversion Corporation during the challenges of COVID-19. The foundation has surpassed all expectations, making it a role model globally."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/462131550?h=93a97494af&title=0&byline=0&portrait=0" ,
            ]
        },

                
        {
            id: '7',
            category: 'tvc',
        catText : ' TVC ',
        catdesc : 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
        catshowreal  : TVCVideo,
            header: ' Aramco Planting Al-Ahsa Park  ',
            description: [
                "The Al-Ahsa Province has always stood out for its diverse natural geography, including an agricultural oasis. We documented Aramco's continuous efforts in planting local trees at the Al-Ahsa National Park to promote vegetation growth and combat desertification."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/896846160?h=8cd46a0d25&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '8',
            category: 'tvc',
        catText : ' TVC ',
        catdesc : 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
        catshowreal  : TVCVideo,
            header: ' Ithra Museum  ',
            description: [
                "In line with the saying 'Art is what the receiver sees,' we produced this wonderful work to embark on a journey of art and culture from the heart of the Ithra museum, talking to experts."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898877123?h=ff060969dd&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '9',
            category: 'tvc',
        catText : ' TVC ',
        catdesc : 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
        catshowreal  : TVCVideo,
            header: ' Global Corridor Artificial Intelligence  ',
            description: [
                "Embarking on an engaging journey through AI Corridor, we joined three developers from different locations with a common goal of collaborating on an artificial intelligence project."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893021260?h=d911aef641&title=0&byline=0&portrait=0" ,
            ]
        },

        
        {
            id: '9',
            category: 'tvc',
        catText : ' TVC ',
        catdesc : 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
        catshowreal  : TVCVideo,
            header: ' Video of Excellence Academy graduates  ',
            description: [
                "We took the stage with the first and second batch graduates of Altamayyuz Academy, capturing their diverse experiences in the academy that makes future leaders."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/896846643?h=13151de7cc&title=0&byline=0&portrait=0" ,
            ]
        },

                
        {
            id: '10',
            category: 'tvc',
        catText : ' TVC ',
        catdesc : 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
        catshowreal  : TVCVideo,
            header: ' Community Misk - Misk Initiatives Incubator',
            description: [
                "A new story with a distinguished client: we launched the third batch of the Misk Initiatives Incubator program. The program is an opportunity for the development and success of your initiative."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898071342?h=c59bd1befe&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '11',
            category: 'tvc',
        catText : ' TVC ',
        catdesc : 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
        catshowreal  : TVCVideo,
            header: ' Our Heroes, Heroes of the Nation',
            description: [
                "In this national work, we are proud to collaborate with Ithra, affirming that our heroes have proven themselves through their actions, contributing to our nation in various fields and tasks."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898356632?h=e988e541c4&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '12',
            category: 'tvc',
        catText : ' TVC ',
        catdesc : 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
        catshowreal  : TVCVideo,
            header: ' DWP Stakeholders',
            description: [
                "What do you know about digital wellbeing? We listened to experts' contributions in the Digital Wellbing program at Ithra, enriching a journey full of scientifically valuable information"
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898347551?h=e16c517c25&title=0&byline=0&portrait=0" ,
            ]
        },


        // {
        //     id: '13',
        //     category: 'videoclips',
        //     catText : ' Video Clips',
        // catdesc : ' We translate your emotions into melodies, words, and shots that touch hearts and express your message in a captivating manner.',
        // catshowreal  : VIDEOCLIPSVideo,
        //     header: 'Aramco -Al-Qadisiyah Saudi Club song',
        //     description: [
        //         "We had the honor of collaborating with Aramco on its initial projects following the transfer of ownership of Al-Qadisiyah Saudi Club. We composed the lyrics, performed the song, and produced a music video titled Qadsawi from the Beginning to the End."
        //     ],
        //     vimeolink: [
        //         '',
        //     ]
        // },

        {
            id: '14',
            category: 'videoclips',
            catText : ' Video Clips',
        catdesc : ' We translate your emotions into melodies, words, and shots that touch hearts and express your message in a captivating manner.',
        catshowreal  : VIDEOCLIPSVideo,
            header: ' Ithra Eid al fitr 2020',
            description: [
                "We wrote and produced the Eid Al-Fitr song for Ithra in 2020, conveying the message in this work that, regardless of circumstances, our joy in the arrival of Eid remains unaffected."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898349091?h=6d2440f60b&title=0&byline=0&portrait=0" ,
            ]
        },

        
        // {
        //     id: '15',
        //     category: 'videoclips',
        //     catText : ' Video Clips',
        // catdesc : ' We translate your emotions into melodies, words, and shots that touch hearts and express your message in a captivating manner.',
        // catshowreal  : VIDEOCLIPSVideo,
        //     header: ' aramco Eid Aladha 2021',
        //     description: [
        //         "Share with us Aramco's joy of Eid al-Adha, and we wrote and produced this work to illustrate the concept of 'Eid with the sight of family and loved ones."
        //     ],
        //     vimeolink: [
        //         '',
        //     ]
        // },


        {
            id: '16',
            category: 'videoclips',
            catText : ' Video Clips',
        catdesc : ' We translate your emotions into melodies, words, and shots that touch hearts and express your message in a captivating manner.',
        catshowreal  : VIDEOCLIPSVideo,
            header: ' Aramco Eid al fitr 2023 ',
            description: [
                "Our traditions as Muslims in celebrating Eid vary worldwide, but our joy is unified. In a creative musical video, we wrote and produced the Eid song for 2023 with Aramco."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893027134?h=b32a474834&title=0&byline=0&portrait=0" ,
            ]
        },

        
        {
            id: '17',
            category: 'videoclips',
            catText : ' Video Clips',
        catdesc : ' We translate your emotions into melodies, words, and shots that touch hearts and express your message in a captivating manner.',
        catshowreal  : VIDEOCLIPSVideo,
            header: ' Ithra Saudi National day 91  ',
            description: [
                "We created a never seen before masterpiece.In collaboration with Ithra, we produced melodies celebrating our beloved country on its 91st National Day."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/660202891?h=d5bae7b4b3&title=0&byline=0&portrait=0" ,
            ]
        },

        
        {
            id: '18',
            category: 'videoclips',
            catText : ' Video Clips',
        catdesc : ' We translate your emotions into melodies, words, and shots that touch hearts and express your message in a captivating manner.',
        catshowreal  : VIDEOCLIPSVideo,
            header: ' Aramco Eid Al Adha 2023  ',
            description: [
                "In an emotional narrative, we wrote, produced, and composed the music for this story set in the living room of a family surrounded by love and care. We watch them celebrate throughout the years as the hero child child grow up. Time passes, the family expands, yet the joy persists, each year with different details."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893019794?h=80b331d068&title=0&byline=0&portrait=0" ,
            ]
        },

            
        {
            id: '19',
            category: 'videoclips',
            catText : ' Video Clips',
        catdesc : ' We translate your emotions into melodies, words, and shots that touch hearts and express your message in a captivating manner.',
        catshowreal  : VIDEOCLIPSVideo,
            header: '  Eid Al Fitr Aramco 2021    ',
            description: [
                "Eid al-Fitr for the year 2021 was different from all the past holidays. We celebrated it with Aramco to document our commitment to precautionary measures and overcome the challenges of COVID."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898314626?h=4d61de8ea1&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '20',
            category: 'documantaries',
            catText : ' Documantaries',
        catdesc : 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
        catshowreal  : DOCUMANTARIESVideo,
            header: '  The Climate Week  ',
            description: [
                " In our signature filming style, we presented an emotional and informative experience asserting the Kingdom's continuous efforts in solving today's climate challenges for a brighter tomorrow for future generations. The narrative is led by children's questions; for them and because of them, we shape the future. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898047687?h=f513a8e251&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '21',
            category: 'documantaries',
            catText : ' Documantaries',
        catdesc : 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
        catshowreal  : DOCUMANTARIESVideo,
            header: '  SME Bank  ',
            description: [
                "  In a professional way of presenting and simplifying information, we wrote and produced this promotional film for the Small and Medium Enterprises Bank (SME), introducing everyone to the distinctive products of Finetech."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893014779?h=a397f6f31e&title=0&byline=0&portrait=0" ,
            ]
        },


        // {
        //     id: '22',
        //     category: 'documantaries',
        //     catText : ' Documantaries',
        // catdesc : 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
        // catshowreal  : DOCUMANTARIESVideo,
        //     header: '   Community Misk  ',
        //     description: [
        //         " We scripted and produced this documentary with a cinematic visual approach, celebrating the youthful energies that lead the journey of entrepreneurship and contribute to making a lasting impact. "
        //     ],
        //     vimeolink: [
        //         '',
        //     ]
        // },

        {
            id: '23',
            category: 'documantaries',
            catText : ' Documantaries',
        catdesc : 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
        catshowreal  : DOCUMANTARIESVideo,
            header: ' Ithra Story   ',
            description: [
                " We had the honor of writing and producing this documentary that recounts the divine light that emanated from Mecca to Medina in the greatest journey in our Islamic history, and about the migration that forever changed the face of civilization. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893017894?h=1f214fde1b&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '24',
            category: 'documantaries',
            catText : ' Documantaries',
        catdesc : 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
        catshowreal  : DOCUMANTARIESVideo,
            header: '   Coffee Initiative ',
            description: [
                " In n an engaging personal tale, farmers in the captivating mountains of Jazan share with us Aramco's initiative to cultivate and produce coffee in Jazan."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/894100452?h=b3fa0e84a4&title=0&byline=0&portrait=0" ,
            ]
        },

        // {
        //     id: '25',
        //     category: 'documantaries',
        //     catText : ' Documantaries',
        // catdesc : 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
        // catshowreal  : DOCUMANTARIESVideo,
        //     header: '  HRH Prince Talal bin Abdulaziz Al Saud  ',
        //     description: [
        //         " We wrote and produced the documentary film that highlights the establishment and growth of the Arab Open University, which aims to make higher education accessible to everyone. We also explore the wise leadership of its founder, Prince Talal bin Abdulaziz Al Saud, who realized the importance of education in shaping the future of the Arab world."
        //     ],
        //     vimeolink: [
        //         '',
        //     ]
        // },

        {
            id: '26',
            category: 'documantaries',
            catText : ' Documantaries',
        catdesc : 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
        catshowreal  : DOCUMANTARIESVideo,
            header: '  Aramco Ithra story ',
            description: [
                " We wrote and produced this documentary to shed light on the King Abdulaziz Center for World Culture (Ithra), that was built to embody the nation’s memory and pride."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893698501?h=ec57ad25fe&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '27',
            category: 'documantaries',
            catText : ' Documantaries',
        catdesc : 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
        catshowreal  : DOCUMANTARIESVideo,
            header: '   WTTCO   ',
            description: [
                " Since 1974, we have witnessed the efforts of the Saline Water Conversion Corporation working on major projects in freshwater production. Officials in this documentary film discuss their continuous achievements in water transportation and technology.  "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/896458189?h=a17f8352a7&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '28',
            category: 'documantaries',
            catText : ' Documantaries',
        catdesc : 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
        catshowreal  : DOCUMANTARIESVideo,
            header: '  The innovation journey at DTR  ',
            description: [
                " We embarked on an innovation journey with the Institute of Research and Innovation in Desalination and Water Treatment to confront the challenges of the water sector, documenting conversations with key experts in the field."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898126602?h=661ed5a29a&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '29',
            category: 'documantaries',
            catText : ' Documantaries',
        catdesc : 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
        catshowreal  : DOCUMANTARIESVideo,
            header: 'Ithra optical illusions',
            description: [
                " With Ithra, we enjoy the optical illusion presentations of the artist Lucas De Giacomo, creating episodes in various locations around Ithra that provide joy in times of social distancing during the pandemic."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898369307?h=031b6d8929&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '30',
            category: 'documantaries',
            catText : ' Documantaries',
        catdesc : 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
        catshowreal  : DOCUMANTARIESVideo,
            header: '  PiCon   ',
            description: [
                " We revived the character of the world-famous Einstein by building an innovative filming site and using creative editing and modification techniques, taking us back to the past and leading us into the future."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/526859151?h=0bf52dab6b&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '31',
            category: 'documantaries',
            catText : ' Documantaries',
        catdesc : 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
        catshowreal  : DOCUMANTARIESVideo,
            header: '  A day in the life of a farmer in Jizan  ',
            description: [
                " Share with us the experience of a coffee farmer in Jazan who participated in Aramco's initiative for coffee cultivation.The goal of this documentary is to highlight the impact of the initiative on local coffee farmers. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/896586092?h=494596537f&title=0&byline=0&portrait=0",
            ]
        },



        {
            id: '32',
            category: 'documantaries',
            catText : ' Documantaries',
        catdesc : 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
        catshowreal  : DOCUMANTARIESVideo,
            header: 'Mukamalah Announcement Video',
            description: [
                " Enter Mukamalah Aviation, once a humble part of Aramco's aviation sector. We chronicled Mukamalah’s transformation from a subsidiary wing to a standalone entity.With a cinematic flair, we illustrated its aspirations and celebrated milestones. In a tapestry of creative graphics, we unveiled the newfound identity and visionary future of Mukamalah. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893028342?h=73c0c13d3e&title=0&byline=0&portrait=0" ,
            ]
        },



        {
            id: '33',
            category: 'tellyourstory',
            catText : ' Tell Your Story',
        catdesc : 'We transform your stories into impactful narratives that touch emotions and immortalize precious moments.',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' SWCC Saudi National day 93  ',
            description: [
                " Inspired by the bedtime tales of legendary heroes, we drew our vision for this work. We show that true heroism isn't about possessing superhuman abilities but harboring a dream and persisting on the path to its realization.And what better embodiment of dream manifestation than the Saline Water Conversion Corporation (SWCC)? We wove a narrative of its shining triumphs on the 93rd Saudi National Day."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893022985?h=6ac74150eb&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '34',
            category: 'tellyourstory',
            catText : ' Tell Your Story',
        catdesc : 'We transform your stories into impactful narratives that touch emotions and immortalize precious moments.',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' Aramco Founding Day  ',
            description: [
                " In Saudi Foundation Day, we went back in time to witness the achievements of Aramco that turned the desert into a thriving urban oasis. The work was written by us and produced by our professional team."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893018763?h=48a97bc7d7&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '35',
            category: 'tellyourstory',
            catText : ' Tell Your Story',
        catdesc : 'We transform your stories into impactful narratives that touch emotions and immortalize precious moments.',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' Ithra story Faisal Baltyuor  ',
            description: [
                "  In our signature execution of documentary cinematic style, we crafted this short film tracing the story of the multi-talented Faisal Baltayyar, a producer and filmmaker, capturing his journey as a pioneer in the Saudi cinema."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893015901?h=6cb4ae21b2&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '36',
            category: 'tellyourstory',
            catText : ' Tell Your Story',
        catdesc : 'We transform your stories into impactful narratives that touch emotions and immortalize precious moments.',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' Ithra Story Ibrahim Alhajjaj ',
            description: [
                " We followed Ibrahim Al-Hajjaj as he narrates his story from the beginning of his artistic career and the different stages in his journey, highlighting how his launch was from Ithra, part of the Ithra Stories series written and produced by our exceptional team."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898359682?h=116168dc60&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '37',
            category: 'tellyourstory',
            catText : ' Tell Your Story',
        catdesc : 'We transform your stories into impactful narratives that touch emotions and immortalize precious moments.',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' HRH Prince Talal bin Abdulaziz Al Saud',
            description: [
                "  We produced this documentary film to shed light on the establishment and growth of the Arab Open University, aiming to make higher education accessible to all. We also explore the wise leadership of its founder, HRH Prince Talal bin Abdulaziz Al Saud, who recognized the importance of education in shaping the future of the Arab world. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898065519?h=309ef815f8&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '38',
            category: 'tellyourstory',
            catText : ' Tell Your Story',
        catdesc : 'We transform your stories into impactful narratives that touch emotions and immortalize precious moments.',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' Story of Tapline',
            description: [
                " This video was produced in honor of the country's first industrial heritage site. Through it, we understand the significance of the pipeline and its impact on the Kingdom during that period.  "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/645681608?h=81c388cc5f&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '39',
            category: 'tellyourstory',
            catText : ' Tell Your Story',
        catdesc : 'We transform your stories into impactful narratives that touch emotions and immortalize precious moments.',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' Ithra Story PETER SANDERS',
            description: [
                " Join us and photographer Peter Sanders on a fascinating journey through his career in photography, wielding his professional camera to share his passion for a significant Islamic event – the migration of the Prophet Muhammad (peace be upon him)"
            ],
            vimeolink: [
                "https://player.vimeo.com/video/894082923?h=cee0153ef7&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '40',
            category: 'tellyourstory',
            catText : ' Tell Your Story',
        catdesc : 'We transform your stories into impactful narratives that touch emotions and immortalize precious moments.',
        catshowreal  : TELLYOURSTORYVideo,
            header: 'Media Excellence Award - Najran ',
            description: [
                "  We played a prominent role in the Media Excellence Award event, moving to the historic and agriculturally rich Najran with its enchanting atmosphere that gave us the talented Ahmed Al Mashref, honored for representing Saudi youth.It was an honor to accompany him in Najran and narrate his story through our lens. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/477979772?h=1841558fe0&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '41',
            category: 'tellyourstory',
            catText : ' Tell Your Story',
        catdesc : 'We transform your stories into impactful narratives that touch emotions and immortalize precious moments.',
        catshowreal  : TELLYOURSTORYVideo,
            header: 'NTDP Abdullah Al-Namlah',
            description: [
                " Abdullah Al Namla, an entrepreneur, shared how his company benefited from the Growth Financing Initiative under the National Technology Development Program and the positive impact of the initiative on their ability to introduce the company to the market."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898321671?h=0d89945395&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '42',
            category: 'tellyourstory',
            catText : ' Tell Your Story',
        catdesc : 'We transform your stories into impactful narratives that touch emotions and immortalize precious moments.',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' HRH Prince Badr Bin Abdulmohsin Al-Saud  ',
            description: [
                " We are honored to be part of producing this rich interview with His Highness Prince Badr bin Abdulmohsen Al Saud, taking us on a tour of his poetry, art, and literature."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/660203816?h=3b12e876c6&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '43',
            category: 'tellyourstory',
            catText : ' Tell Your Story',
        catdesc : 'We transform your stories into impactful narratives that touch emotions and immortalize precious moments.',
        catshowreal  : TELLYOURSTORYVideo,
            header: ' Ithra Story Sarah Alrehaili ',
            description: [
                "  In an emotional cinematic style, the video takes us on a journey with lawyer Sarah Al-Rahili, the 2016 Reader of the Year in the IRead competition. We stop at inspirational moments from her life with books and thoughts, and her educational journey full of achievements. A part of the Ithra Stories series, all written and produced by our exceptional team. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898862934?h=229c4e0f38&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '44',
            category: 'animation',
            catText : ' 2D & 3D Animation',
        catdesc : 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
        catshowreal  : ANIMATIONVideo,
            header: '  Almajdouie Holding Saudi National Day 92 ',
            description: [
                "We celebrated with Al-Majdouie Holding the 92nd Saudi National Day, creating a creative work with highest quality 2D and 3D animation elements."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893020707?h=2169beec6d&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '45',
            category: 'animation',
            catText : ' 2D & 3D Animation',
        catdesc : 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
        catshowreal  : ANIMATIONVideo,
            header: ' SWCC Founding Day',
            description: [
                " On Saudi foundation day 2023, we traveled through time to witness the developments of the water sector since the beginnings of the kingdom in a creative historical approach. We rekindled the feelings of pride with Saline Water Conversion Corporation (SWCC)."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893022321?h=6ac9a8f7a9&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '46',
            category: 'animation',
            catText : ' 2D & 3D Animation',
        catdesc : 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
        catshowreal  : ANIMATIONVideo,
            header: ' Ithra and Saudi Airlines ',
            description: [
                "We were delighted to participate in producing the promotional video for the Ithra Design Competition in collaboration with Saudi Airlines, which aims to support creative individuals by providing them with international platforms to showcase their talents."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893702984?h=f24ddb6990&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '47',
            category: 'animation',
            catText : ' 2D & 3D Animation',
        catdesc : 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
        catshowreal  : ANIMATIONVideo,
            header: '  MOC Founding Day',
            description: [
                " With unique visual elements and the tremendous efforts from the Proeffect team,we wrote and produced this video for the Saudi Foundating day with the Ministry of Culture, celebrating our pride and documenting the events that took place during the establishment of the first Saudi state."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898114244?h=8cb84580c2&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '48',
            category: 'animation',
            catText : ' 2D & 3D Animation',
        catdesc : 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
        catshowreal  : ANIMATIONVideo,
            header: '  SWCC Saudi Founding Day 2023 ',
            description: [
                "  In celebration of Saudi Founding Day in 2023, we wrote and produced a special film on the Kingdom of Saudi Arabia's journey over three centuries,  coinciding with the history of Saline Water Conversion Corporation from that era to the present."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898123972?h=6b192a9ac6&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '49',
            category: 'animation',
            catText : ' 2D & 3D Animation',
        catdesc : 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
        catshowreal  : ANIMATIONVideo,
            header: ' SPE 60th Anniversary ',
            description: [
                "On the occasion of completing 60 years since the establishment of the Society of Petroleum Engineers, we produced this work using graphics elements to document its founding story and achievements."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898306115?h=42a5d54b5e&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '50',
            category: 'animation',
            catText : ' 2D & 3D Animation',
        catdesc : 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
        catshowreal  : ANIMATIONVideo,
            header: ' Children\'s Literary Week ',
            description: [
                " We wrote and produced a promotional graphics film for the Children Literature Week event organized by the The Literature, Publishing & Translation Commission. The ad invites viewers to register in the exciting and diverse activities of the event."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893693740?h=aea86953fd&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '51',
            category: 'animation',
            catText : ' 2D & 3D Animation',
        catdesc : 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
        catshowreal  : ANIMATIONVideo,
            header: '  ithra 2021 Calendar ',
            description: [
                " Every year, we anticipate the surprises presented by Ithra to its visitors. In this video, we accompanied them to see what the 2021 calendar holds from exciting events to  programs."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898363242?h=9bb29ee09f&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '52',
            category: 'animation',
            catText : ' 2D & 3D Animation',
        catdesc : 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
        catshowreal  : ANIMATIONVideo,
            header: ' the 2012 Asian Men\'s Handball Championship',
            description: [
                " We participated in producing  TV breaks for the 2012 Asian Men's Handball Championship."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898283672?h=5c30370088&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '53',
            category: 'animation',
            catText : ' 2D & 3D Animation',
        catdesc : 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
        catshowreal  : ANIMATIONVideo,
            header: ' the 2012 Asian Men\'s Handball Championship ',
            description: [
                " We participated in producing  TV breaks for the 2012 Asian Men's Handball Championship."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898285349?h=d32ec0a09a&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '54',
            category: 'animation',
            catText : ' 2D & 3D Animation',
        catdesc : 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
        catshowreal  : ANIMATIONVideo,
            header: ' the 2012 Asian Men\'s Handball Championship ',
            description: [
                " We participated in producing  TV breaks for the 2012 Asian Men's Handball Championship."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898288016?h=31094edd83&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '55',
            category: 'animation',
            catText : ' 2D & 3D Animation',
        catdesc : 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
        catshowreal  : ANIMATIONVideo,
            header: '  the 2012 Asian Men\'s Handball Championship ',
            description: [
                " We participated in producing  TV breaks for the 2012 Asian Men's Handball Championship."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898288759?h=3e5cd6b1cf&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '56',
            category: 'animation',
            catText : ' 2D & 3D Animation',
        catdesc : 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
        catshowreal  : ANIMATIONVideo,
            header: '  introduction for the 2012 Asian Men\'s Handball Championship ',
            description: [
                " We produced the introduction video for the 2012 Asian Men's Handball Championship."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898290216?h=8e31a1a2f7&title=0&byline=0&portrait=0" ,
            ]
        },

        
        {
            id: '57',
            category: 'tvservices',
            catText : 'TV Services ',
        catdesc : 'We provide comprehensive TV Services, delivering high-quality broadcasting solutions that ensure your content reaches audiences with clarity, impact, and unparalleled professionalism.',
        catshowreal  : TVSERVICESVideo,
            header: '  Program introduction Hala World Cup',
            description: [
                " We lived the atmosphere of the World Cup through our cooperation with Saudi television in producing the introduction to the Hala World Cup program, which covers the most important events of the Club World Cup."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/899236967?h=98448d7b91&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '58',
            category: 'tvservices',
            catText : 'TV Services ',
        catdesc : 'We provide comprehensive TV Services, delivering high-quality broadcasting solutions that ensure your content reaches audiences with clarity, impact, and unparalleled professionalism.',
        catshowreal  : TVSERVICESVideo,
            header: ' introduction Expo  ',
            description: [
                " We celebrated the Kingdom’s wiin to host Expo 2030 event through an opening graphics video for the coverage."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898998851?h=e5b321c4d9&title=0&byline=0&portrait=0" ,
            ]
        },



        {
            id: '59',
            category: 'tvservices',
            catText : 'TV Services ',
        catdesc : 'We provide comprehensive TV Services, delivering high-quality broadcasting solutions that ensure your content reaches audiences with clarity, impact, and unparalleled professionalism.',
        catshowreal  : TVSERVICESVideo,
            header: ' Introduction to the Yellow League, First Division',
            description: [
                " We collaborated with Yelo League Premier League to produce its opening video."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/899022207?h=6c7fd81ef3&title=0&byline=0&portrait=0" ,
            ]
        },



        {
            id: '60',
            category: 'tvservices',
            catText : 'TV Services ',
        catdesc : 'We provide comprehensive TV Services, delivering high-quality broadcasting solutions that ensure your content reaches audiences with clarity, impact, and unparalleled professionalism.',
        catshowreal  : TVSERVICESVideo,
            header: '   Program introduction 3X3  ',
            description: [
                " We were pleased to be part of the 3x3 cultural entertainment program by producing the opening introduction video."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898992686?h=e7e5a9571c&title=0&byline=0&portrait=0" ,
            ]
        },



        {
            id: '61',
            category: 'tvservices',
            catText : 'TV Services ',
        catdesc : 'We provide comprehensive TV Services, delivering high-quality broadcasting solutions that ensure your content reaches audiences with clarity, impact, and unparalleled professionalism.',
        catshowreal  : TVSERVICESVideo,
            header: ' Program introduction Elmawkef ',
            description: [
                " We collaborated with the Saudi Television to produce the introduction for the program \"Al-Mawqif,\" which focuses on local and global political news."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/899000475?h=a3e41a9802&title=0&byline=0&portrait=0" ,
            ]
        },



        {
            id: '62',
            category: 'tvservices',
            catText : 'TV Services ',
        catdesc : 'We provide comprehensive TV Services, delivering high-quality broadcasting solutions that ensure your content reaches audiences with clarity, impact, and unparalleled professionalism.',
        catshowreal  : TVSERVICESVideo,
            header: '  Introduction to the Riyadh Summit',
            description: [
                " We are proud to produce the introduction video for the Riyadh Summit between the Gulf Cooperation Council and the memebers os Association of Southeast Asian Nations."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/899000785?h=5fa241d2b0&title=0&byline=0&portrait=0" ,
            ]
        },



        {
            id: '63',
            category: 'eventhighlights',
            catText : 'Event Highlights  ',
        catdesc : 'We transform your events into unforgettable experiences and document them with precision and creativity to live on in memory.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: ' Closing of Climate Week ',
            description: [
                "We are proud to take part in the success of the Climate Week held in Riyadh, where we documented the Kingdom's ongoing efforts in addressing climate challenges for a brighter tomorrow for future generations. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898059664?h=f14b869505&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '64',
            category: 'eventhighlights',
            catText : 'Event Highlights  ',
        catdesc : 'We transform your events into unforgettable experiences and document them with precision and creativity to live on in memory.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: '  Vietnam Culture Week  ',
            description: [
                "One of our creative works, crafted with passion, 'Days of Vietnam Culture,' where the King Abdulaziz Center for World Culture (Ithra) adorned with Vietnamese Red Dragon culture during the Ithra Cultural Days."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898863962?h=a2ee00fb64&title=0&byline=0&portrait=0" ,
            ]
        },


        {
            id: '65',
            category: 'eventhighlights',
            catText : 'Event Highlights  ',
        catdesc : 'We transform your events into unforgettable experiences and document them with precision and creativity to live on in memory.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: ' ithra downtown ',
            description: [
                " In the wonderful winter atmosphere, we moved between many activities and events, documenting them in Ithra Downtown."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/507461487?h=054d8cc4b1&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '66',
            category: 'eventhighlights',
            catText : 'Event Highlights  ',
        catdesc : 'We transform your events into unforgettable experiences and document them with precision and creativity to live on in memory.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: '  Iqraa ',
            description: [
                " Speaking with us IRead award winners, Sami, Mohammed, and Amjad, about their experience with Ithra Annual Reading Competition, documenting Ithra's efforts in spreading the culture of reading to reach all educational stages."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898361736?h=ca8b38d445&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '67',
            category: 'eventhighlights',
            catText : 'Event Highlights  ',
        catdesc : 'We transform your events into unforgettable experiences and document them with precision and creativity to live on in memory.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: ' Eastern Province Season Summary ',
            description: [
                "In this video, we explore the cities of the Eastern Region, capturing the colourful visual of its beautiful scenes... In the Eastern Season #Yalla_Sharqiya."
            ],
            vimeolink: [
                "https://player.vimeo.com/video/898299154?h=6bdb7ad240&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '68',
            category: 'eventhighlights',
            catText : 'Event Highlights  ',
        catdesc : 'We transform your events into unforgettable experiences and document them with precision and creativity to live on in memory.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: ' Eastern season ',
            description: [
                " We are proud to document unforgettable moments in the Eastern Province during various activities and events.C92"
            ],
            vimeolink: [
                "https://player.vimeo.com/video/922200065?h=c3b15d86ae&title=0&byline=0&portrait=0" ,
            ]
        },

        {
            id: '69',
            category: 'eventhighlights',
            catText : 'Event Highlights  ',
        catdesc : 'We transform your events into unforgettable experiences and document them with precision and creativity to live on in memory.',
        catshowreal  : EVENTHIGHLIGHTSVideo,
            header: ' SYNS ',
            description: [
                "We covered the \"SYNC\" digital wellbeing initiative held at Ithra, aiming to understand the current state of technology and its impact on our lives, through seminars with several experts in the field. "
            ],
            vimeolink: [
                "https://player.vimeo.com/video/893695294?h=f702d4d3c0&title=0&byline=0&portrait=0" ,
            ]
        },


        
        {
            id: '70',
            category: 'bts',
            catText : 'BTS',
        catdesc : 'We capture the essence of Behind The Scenes (BTS), offering a unique glimpse into the creative process and showcasing the hard work and dedication that goes into making every project a masterpiece.',
        catshowreal  : BTSVideo,
            header: ' BTS Menacw ',
            description: [
                "  Behind the scenes ou our tremendous effort to exert and create the Climate Week work. We brainstormed and wrote this global work, using the latest production technologies (virtual studio) to convey the message of the Kingdom of Saudi Arabia represented by the Ministry of Energy at the Climate Week conference."
            ],
            vimeolink: [
                                "https://player.vimeo.com/video/893697407?h=ddd16f366f&title=0&byline=0&portrait=0"                ,
            ]
        },


        {
            id: '71',
            category: 'bts',
            catText : 'BTS',
        catdesc : 'We capture the essence of Behind The Scenes (BTS), offering a unique glimpse into the creative process and showcasing the hard work and dedication that goes into making every project a masterpiece.',
        catshowreal  : BTSVideo,
            header: ' BTS Let\'s Start Together ',
            description: [
                " Wonderful memories and unparalleled efforts... Behind the scenes of a work  Let's Start Together in the Eastern Season."
            ],
            vimeolink: [
            "https://player.vimeo.com/video/898293302?h=27cb42ead7&title=0&byline=0&portrait=0" ,
            ]
        },
        
        {
            id: '72',
            category: 'audio',
            catText : 'Designing Auditory Identities',
        catdesc : 'We design a distinctive auditory identity that enhances your brand and sets you apart from competitors.',
        catshowreal  : AudioVideo,
            header: '',
            description: [
                ""
            ],
            vimeolink: [
            "" ,
            ]
        },

        {
            id: '73',
            category: 'storage',
            catText : 'Cloud Storage Archiving',
        catdesc : 'Sustainable storage in the cloud world preserves your dreams and projects in one place.',
        catshowreal  : StorageVideo,
            header: '',
            description: [
                ""
            ],
            vimeolink: [
            "" ,
            ]
        },

















    ];
    









    
    useEffect(() => {
        const playButtons = document.querySelectorAll('.play-video');

        playButtons.forEach(button => {
            const video = button.previousElementSibling; // Assuming the video is always right before the button

            button.addEventListener('click', function() {
                if (video.paused) {
                    video.play();
                    button.style.display = 'none'; // Hide play button
                }
            });

            video.addEventListener('click', function() {
                if (!video.paused) {
                    video.pause();
                    button.style.display = ''; // Show play button

                }
            });
            video.addEventListener('ended', function() {
                button.style.display = ''; // Show play button when video ends
            });
        });
    }, []);


    const query = useQuery();
    const category = query.get('category'); // This extracts the category from the URL

    const filteredProjects = ProjectsData.filter(project => project.category === category);

    if (filteredProjects.length === 0) {
        // Return nothing or an optional message
        return null; // Or, for example, return <div>No projects found for this category.</div>;
    }

    return(
        <>
        


        <section className="work-page-header">
            <img className="overlay" src={OverlayImage} alt="overlay"/>

            <div className="container">
                <div className="work-header-content">
                    <p>
                        {filteredProjects[0].catdesc}
                    </p>

                    {/* <h1> W <img src={OLetter} alt="O letter"/> RK </h1> */}
                    <h1> {filteredProjects[0].catText}</h1>
                </div>
            </div>
        </section>






        <section className="our-work">
            <div className="container">
                <div className="header">
                    <h2> SCROLL TO EXPLORE <img src={Arrow} alt="arrow"/> </h2>

                </div>


                <video src={filteredProjects[0].catshowreal} alt="video main" autoPlay muted loop playsInline> </video>


                <div className="row">


                {filteredProjects.map((project, index) => (
                <div className="col-lg-6 col-md-6 col-sm-12 col-12" key={index}>
                    <div className="project">
                        <h1>{project.header}</h1>
                        <div className="project-video">
                        {project.vimeolink ? (
                                // If project.vimeolink is present, render the iframe
                                <iframe title={project.header} src={project.vimeolink} width="100%" height="450" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                            ) : (
                                // If project.vimeolink is not present, render a placeholder or message
                                <h1>No Video To Show</h1>
                            )}
                        </div>
                        <p>{project.description.join(" ")}</p> {/* Assuming description is an array of strings */}
                        
                    </div>
                </div>
            ))}



                    
                </div>
            </div>
        </section>






        </>
    )
}

export default WorkPage;