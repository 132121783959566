
import CLOUDSTORAGEARCHIVINGVideo from './showrealsservices/storage.mp4';
import videoImg from './images/digitalassests/video.png';
import audioImg from './images/digitalassests/audio.png';
import ImageImg from './images/digitalassests/image.png';

function DigitalAssests(){
    return(
        <>
            <section className="digital-assests">
                <div className="container">
                    <div className="digital-assests-header">
                        <h1 data-aos-delay="200" data-aos="fade-in">DIGITAL ASSET MANAGEMENT </h1>
                        <h2 data-aos-delay="300" data-aos="fade-in"> Together, we will reduce digital waste by saving your materials in a private digital wallet.</h2>
                    </div>

                    <div data-aos-delay="400" data-aos="fade-in" className="three-d-model-video">
                        <video  src={CLOUDSTORAGEARCHIVINGVideo} playsInline autoPlay loop muted alt="project-video" > </video>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="digital-content">
                                <h1 data-aos-delay="100" data-aos="fade-in">
                                The "Digital Assets" feature allows you to save your raw materials (video, audio, photos) through private and secure cloud servers with large storage capacity.

                                </h1>
                                <span data-aos-delay="200" data-aos="fade-in">

                                </span>

                                <p data-aos-delay="300" data-aos="fade-in">
                                
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="digital-content">
                                <h1 data-aos-delay="100" data-aos="fade-in">
                                You can rely on this feature to access your materials whenever needed and to reduce the cost of producing future projects with "ProEffect".
                                </h1>
                                

                                <div className="icons">
                                    <div data-aos-delay="100" data-aos="fade-in" className="icon">
                                        <img src={videoImg} alt="video" />
                                        <h3> VIDEO </h3>
                                    </div>

                                    <div data-aos-delay="200" data-aos="fade-in" className="icon">
                                        <img src={audioImg} alt="audio" />
                                        <h3>  AUDIO </h3>
                                    </div>

                                    <div data-aos-delay="300" data-aos="fade-in" className="icon">
                                        <img src={ImageImg} alt="Image" />
                                        <h3> IMAGE </h3>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default DigitalAssests;