

import ContactBack from "../images/contact/Rectangle-5.webp";
import Map1 from "../images/maps/khob.png";
import Map2 from "../images/maps/reyad.png";


function ContactUs(){

        // State to keep track of the currently active map
    // const [activeMap, setActiveMap] = useState('riyadh');

    // Function to change the active map
    // const showMap = (mapName) => {
    //     setActiveMap(mapName);
    // };



    return(
        <>
            
            <section className="contact-page-header ar">
                {/* <img className="overlay" src={overlayImage} alt="overlay"/> */}
                <img className="contact back" src={ContactBack} alt="contact back"/>
                <div className="container">
                    <div className="contact-page-header-content">
                        <h1> بروإفكت, <br/>
                        شريكك <br></br> الإبداعي.</h1>

                        <h2>
                            <span></span>

                            نؤمــــن فــــــــي بروافكت بــــقــــــــوة العمــــــــل الجماعـي والشــــراكــــــــــــة مــــــــع
                            أولــــئــــــــــــك الذيــــــــــــن يشاركونــــــــنا شــــغفنـــــــــــا بالإبــــداع والابــــــتكــــــــــــــار.
                            إذا كنــــــــــــت ترغــــــــــــب فــــــــي المساهمة فــــــــي قصتنا وتــــرك بصمتــــــــك
                            فــــــــي عالــــــــم الإنتــــــاج الإبداعــــي، فنحــــــن ندعــــــــــوك للتواصــــل معنــــــــــا
                            ً واكتشــــــاف كيــــــف يمكننا تحقيــــــق العظمــــــة معــــــا

                            <i className="las la-arrow-down"></i>
                            <span></span>
                        </h2>



                        <span className="line"></span>
                    </div>
                </div>
            </section>





            <section className="contact-us ar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 co-md-4 col-sm-12 col-12">
                            <div className="contact-us-content">
                                <div className="box">
                                    <h2>
                                        لننطلق<br></br> معا 
                                    </h2>
                                </div>

                                <span></span>

                                <div className="contact-info">
                                    <div>
                                        <h2> التواصل الإجتماعي </h2>
                                        <ul>
                                            <li>
                                                <a rel='noreferrer' target="_blank" href="https://mobile.twitter.com/proeffect_sa" aria-label="twitter external link">
                                                    تويتر
                                                </a>
                                            </li>

                                            <li>
                                                <a rel='noreferrer' target="_blank" href="https://www.linkedin.com/company/proeffectsa" aria-label="linkedin external link">
                                                    لينكدإن
                                                </a>
                                            </li>

                                            <li>
                                                <a rel='noreferrer' target="_blank" href="https://www.instagram.com/proeffect_sa/" aria-label="instagram external link">
                                                    انستجرام
                                                </a>
                                            </li>

                                            
                                            <li>
                                                <a rel='noreferrer' target="_blank" href="https://youtube.com/channel/UCJwsp9ypAg-lbJtzSrlyoig" aria-label="youtube external link">
                                                    يوتيوب
                                                </a>
                                            </li>


                                            
                                            <li>
                                                <a rel='noreferrer' target="_blank" href="https://vimeo.com/proeffectmedia" aria-label="vimeo external link">
                                                    فيمو
                                                </a>
                                            </li>

                                        </ul>
                                    </div>





                                    <div>
                                        <h2> التواصل </h2>
                                        <ul>
                                            <li>
                                                <a rel='noreferrer' target="_blank" href="mailto:info@proeffect.sa" aria-label="email external link">
                                                    info@proeffect.sa
                                                </a>
                                            </li>

                                            <li>
                                                <a  rel='noreferrer' target="_blank" href="mailto:jobs@proeffect.sa" aria-label="job email external link">
                                                    للوظائف : jobs@proeffect.sa
                                                </a>
                                            </li>



                                            <li>
                                                <a rel='noreferrer' target="_blank" href="tel:+966138844144" aria-label="Instagram external link">
                                                    +966 138 844 144
                                                </a>
                                            </li>

                                        </ul>
                                    </div>


                                    <div>
                                        <h2> العنوان </h2>
                                        <ul>
                                            <li>
                                                <h3>
                                                    الرياض
                                                    <span> 3620 طريق الملك عبدالله الفرعي , الملك 6414</span>
                                                </h3>
                                            </li>

                                            <li>
                                                <h3>
                                                    الخبر
                                                    <span> 208 شارع 21 العليا الخبر ,  34447  </span>
                                                </h3>
                                            </li>

                                        </ul>
                                    </div>



                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 co-md-8 col-sm-12 col-12">
                            <div className="form">
                                <form action="">
                                    <div className="input">
                                        <label>  الإسم </label>
                                        <input type="text" placeholder="" required/>
                                    </div>
            
                                    <div className="input">
                                        <label>  البريد الإلكتروني </label>
                                        <input type="email" placeholder="" required/>
                                    </div>
            
                                    
                                    <div className="input">
                                        <label>  رقم الهاتف </label>
                                        <input type="tel" placeholder="" required/>
                                    </div>
            
                                    
                                    
                                    <div className="input">
                                        <label>  عن ماذا تبحث ؟ </label>
                                        <select required>
                                            <option value="الإنتاج الإعلان"> الإنتاج الإعلان </option>
                                            <option value="صناعة الفيديو كليب">صناعة الفيديو كليب </option>
                                            <option value="إنتاج الوثائقيات"> إنتاج الوثائقيات </option>
                                            <option value="Tell your Story"> توثيق القصص </option>
                                            <option value="الرسوم المتحركة ثنائية وثلاثية الأبعاد"> الرسوم المتحركة ثنائية وثلاثية الأبعاد </option>
                                            <option value="خدمات التلفزيون"> خدمات التلفزيون </option>
                                            <option value="تغطية الفعاليات">تغطية الفعاليات </option>
                                            <option value="BTS"> BTS </option>
                                            <option value="الأرشفة والتخزين السحابي "> الأرشفة والتخزين السحابي  </option>
                                            <option value="تصميم الهوية السمعية">تصميم الهوية السمعية</option> 

                                        </select>
                                    </div>
            
            
                                    <div className="input">
                                        <label>رسالتك </label>
                                        <textarea required placeholder="Hello."></textarea>
                                    </div>
            
            
                                    <button type="submit">إرسال</button> 
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="map-section ar">
                <div className="container">
                    <div className="map-content">
                    <div className="maps">
                        <div id="map-riyadh" className="active">
                            <img src={Map2} alt="map riyadh" />
                            <span> </span>
                        </div>
                
                    </div>

                    <div className="buttons">
                        <a rel='noreferrer' target='_blank' href="https://maps.app.goo.gl/4Vsc25oZnDc7cNNo8?g_st=iwb" >الرياض</a>
                        <a rel='noreferrer' target='_blank' href="https://www.google.com/maps/place/Proeffect/@26.3022654,50.1770251,15z/data=!4m5!3m4!1s0x0:0x4b0d6c89087b20ba!8m2!3d26.3022654!4d50.1770251" > الخبر </a>
                    </div>
                    </div>
                </div>
                </section>












        </>
    )
}

export default ContactUs;