

import Wheel from "./images/objects/wheel.png";
import overlayImage from "./images/Rectangle -1.webp";
import  ELetter from "./images/objects/E.png";
import ValueImage from './images/value/1.webp';
import GoalImage from './images/goal/1.webp';

import visionImg from "./images/ourvision/1.png";
import visionImg2 from "./images/ourvision/2.png";



import aboutServicesImg from "./images/aboutservices/1.webp";
import whyus from "./images/whyus/1.webp";

import Oletter from "./images/objects/goals.png";
import Cletter from "./images/objects/c.png";
import Circles from "./images/icons/circles.png";

import Badr from './images/team/badr.png';
import abdullah from './images/team/abdullah.jpg';
import hmood from './images/team/hmood.jpg';
import mohamedsaleh from './images/team/mohamedsaleh.jpg';


import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Navigation} from 'swiper';

import { Link as RouterLink } from "react-router-dom";

function AboutPage(){
    return(
        <>
    

<section className="about-page-header">
    <div className="container">
        <div className="about-page-header-content">
            <h1 data-aos-delay="100" data-aos="fade-in">  We break the monotony of the usual. </h1>

                {/* <p>
                    Hello <br/> Friend WE ARE PROEFFECT — A <br/>
                    Creative Production House <br/> BASED IN RIYADH, El Khabar.
                </p> */}

                <h2 data-aos-delay="200" data-aos="fade-in">Translating the <span data-aos-delay="300" data-aos="fade-in"> idea </span>.</h2>
                <h3>  into <span data-aos-delay="500" data-aos="fade-in"> reality </span> </h3>
        </div>
    </div>
</section>




{/*  End about-page-header Section */}



{/*  Start About Ad Section */}



<section className="about-ad">
    <div className="container">
        <div className="about-ad-content">
            <h1 data-aos-delay="100" data-aos="fade-in"> AB <img src={Wheel} alt="wheal"/> UT</h1>

            <p data-aos-delay="200" data-aos="fade-in">
                <strong>Let's narrate the story of the kingdom together:<br></br></strong>
                <br></br>
                From the heart of the Kingdom of Saudi Arabia, Proeffect, a pioneer in creative production, launched with a 
                different perspective in the world of advertising and documentary production.
            </p>
        </div>
    </div>
</section>




{/*  End About Ad Section */}




{/*  Start Our Vision Section */}



<section className="our-vision">
<img data-aos-delay="100" data-aos="fade-in" src={visionImg2} alt="boy playing on phone"/>

    <div className="container">
        <div className="our-vision-content">
            <p data-aos-delay="200" data-aos="fade-in">

                <strong> Our contributions to the Kingdom's Vision 2030:<br></br> </strong>
                <br></br>
                We believe and strive to play an effective role in achieving the Kingdom's Vision 2030 through the creative production we undertake to narrate the story of transformation and growth in the Kingdom, emphasizing innovation and sustainable development as fundamental pillars in everything we do.
            </p>
        </div>
    </div>
</section>

<section className="our-vision-image">
    <img data-aos-delay="100" data-aos="fade-in" src={visionImg} alt="boy playing on phone"/>
</section>





{/*  End Our Vision Section */}


{/*  Start Our Value Section */}

    <section className="our-value">
        <div className="container">
            <div className="our-value-content">
                <div className="header">
                    <h3 data-aos-delay="100" data-aos="fade-in"> OUR </h3>
                    <h1 data-aos-delay="200" data-aos="fade-in"> VALU <img src={ELetter} alt="E letter"/> </h1>
                </div>

                <div data-aos-delay="100" data-aos="fade-in" className="our-value-slider">
                    <div className="buttons">
                        <button className="prev-btn"><i className="las la-arrow-left"></i></button>
                        <button className="next-btn"><i className="las la-arrow-right"></i></button>
                    </div>

                    <Swiper  grabCursor={true} spaceBetween={50} slidesPerView={1} navigation={{
                        nextEl: '.our-value-slider .next-btn',
                        prevEl: '.our-value-slider .prev-btn',
                        }} 
                            modules={[Navigation]}
                            >
                        <SwiperSlide>

                            <div className="value">
                                <h2> 01 </h2>

                                <div>
                                    <h1> Sustainable Development </h1>
                                    <p>
                                    At PROEFFECT, we recognize the importance of sustainable development and are committed to integrating its practices into all our activities.
                                    </p>

                                    <p>
                                    We strive to reduce the environmental footprint of our productions by using innovative and eco-friendly technologies.

                                    </p>

                                    <p>
                                    We encourage social responsibility and participate in initiatives that enhance awareness of the importance of preserving our natural resources.
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>

                            <div className="value">
                                <h2> 02 </h2>

                                <div>
                                    <h1> Exploring new horizons </h1>
                                    <p>
                                    With an eye towards the future, PROEFFECT aims to explore new horizons in the field of creative production. We harness the capabilities of artificial intelligence with professionalism to achieve our partners' goals.
                                    </p>
                                    <p>
                                    We look for new ways to integrate virtual reality and augmented reality into our creations.
                                    </p>

                                    <p>
                                    We strive to offer unique and immersive experiences that exceed our clients' expectations and elevate creative content to new levels of innovation and impact.
                                    </p>
                                </div>
                            </div>
                            </SwiperSlide>



                        </Swiper>
                </div>


                <img data-aos-delay="100" data-aos="fade-in" src={ValueImage} alt="boy playing on phone"/>

            </div>
        </div>
    </section>




{/*  Start Our Value Section */}



{/*  Start Our Goals Section */}

<section className="our-goals">
    <img src={overlayImage} className="overlay" alt="overlay"/>
    <div className="container">
        <div className="our-goals-content">
            <div className="header">
                <h2 data-aos-delay="100" data-aos="fade-in"> OUR </h2>
                <h1 data-aos-delay="200" data-aos="fade-in"> MISSI <img src={Oletter} alt="O letter"/> N</h1>
            </div>


            <div className="body">
                <div className="left">
                    <p data-aos-delay="100" data-aos="fade-in">
                        <strong> At Proeffect : </strong>
                        <br></br>
                        <br></br>
                        Our approach to content goes beyond mere evaluation, venturing into creating exceptional experiences that narrate success stories and document achievements. We strive to be the voice that narrates, the eye that witnesses, and the heart that feels everything exceptional and inspiring in the Kingdom.
                    </p>

                    {/* <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry’s standard dummy
                        text ever since the 1500s,
                    </p> */}

                    <span></span>
                </div>


                <div data-aos-delay="200" data-aos="fade-in" className="right">
                    <img data-aos-delay="100" data-aos="fade-in"  src={GoalImage} alt="boy playing on phone"/>

                </div>
            </div>
        </div>
    </div>
</section>





{/*  End Our Goals Section */}



{/*  Start Why us Section */}



<section className="why-us">
    <div className="container">
        <div className="why-us-content">
            <h2  data-aos-delay="100" data-aos="fade-in"> WHY </h2>
            <h1 data-aos-delay="200" data-aos="fade-in"> PROEFFE <img src={Cletter} alt="C Letter"/> T </h1>
        


        <div data-aos-delay="100" data-aos="fade-in" className="why-us-slider">
            <div   className="buttons">
                <button className="prev-btn"><i className="las la-arrow-left"></i></button>
                <button className="next-btn"><i className="las la-arrow-right"></i></button>

            </div>

            <Swiper grabCursor={true} spaceBetween={50} slidesPerView={1} navigation={{
                        nextEl: '.why-us-slider .next-btn',
                        prevEl: '.why-us-slider .prev-btn',
                        }} 
                            modules={[Navigation]}
                            >
                <SwiperSlide>
                <div className="slide">
                    <div className="res">
                        <h2> 01 </h2>

                        <div>
                            <h1>Innovation </h1>
                            <p>
                            Our approach transcends the ordinary; we always strive for new horizons and introduce innovative ideas that make a difference.
                            </p>
                        </div>
                    </div>
                </div>
                </SwiperSlide>

                <SwiperSlide>

                <div className="slide">
                    <div className="res">
                        <h2> 02 </h2>

                        <div>
                            <h1> Quality </h1>
                            <p>
                                We adhere to the highest standards in every project, believing that attention to detail is the secret to excellence.
                            </p>
                        </div>
                    </div>
                </div>
                </SwiperSlide>


                <SwiperSlide>

                <div className="slide">
                    <div className="res">
                        <h2> 03 </h2>

                        <div>
                            <h1> Partnership </h1>
                            <p>
                            We view every client as a partner in success, working side by side to achieve their vision with passion and precision.
                            </p>
                        </div>
                    </div>
                </div>
                </SwiperSlide>


                
                <SwiperSlide>

                <div className="slide">
                    <div className="res">
                        <h2> 04 </h2>

                        <div>
                            <h1> Modern Technologies </h1>
                            <p>
                                We use the latest technologies to ensure results that exceed expectations.
                            </p>
                        </div>
                    </div>
                </div>
                </SwiperSlide>


                <SwiperSlide>

                <div className="slide">
                    <div className="res">
                        <h2> 05 </h2>

                        <div>
                            <h1> Extensive Experience </h1>
                            <p>
                            Years of meticulous work with some of the most important institutions in the Kingdom.
                            </p>
                        </div>
                    </div>
                </div>
                </SwiperSlide>



                </Swiper>


            </div>





        </div>


    </div>

    <img data-aos-delay="100" data-aos="fade-in" src={whyus} alt="boy playing on phone"/>


</section>





{/*  End Why us Section */}



{/*  Start About Services Section */}

<section className="about-services">
    <div className="container">
        <div className="about-services-content">
            <div className="left">
                <div>
                    <h1 data-aos-delay="100" data-aos="fade-in"> OUR </h1>
                    <h1 data-aos-delay="200" data-aos="fade-in">  SERVICES </h1>

                </div>

                <p> Starting with the idea,
                    through creative writing,
                    to the artistic direction.

                    </p>
            </div>

            <div className="right">
                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1 data-aos-delay="100" data-aos="fade-in">  TVC </h1>
                    <RouterLink to="/work?category=tvc" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>
                
                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1 >  Video clips </h1>
                    <RouterLink to="/work?category=videoclips" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>

                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1>  Documentaries  </h1>
                    <RouterLink to="/work?category=documantaries" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>

                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1>  Tell your Story  </h1>
                    <RouterLink to="/work?category=tellyourstory" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>



                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1>  2D & 3D Animation </h1>
                    <RouterLink to="/work?category=animation" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>


                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1>  TV Services </h1>
                    <RouterLink to="/work?category=tvservices" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>

                <div data-aos-delay="100" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1>  Event Highlights </h1>
                    <RouterLink to="/work?category=eventhighlights" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>

                
                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1> BTS </h1>
                    <RouterLink to="/work?category=bts" aria-label="open-service">
                        <i className="las la-plus"></i>
                    </RouterLink>
                </div>

                    
                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1> Archiving and cloud storage for business and creative productions.</h1>
                    <span></span>
                </div>

                <div data-aos-delay="200" data-aos="fade-in" className="service">
                    <img src={Circles} alt="circles-icon"/>
                    <h1> The auditory identity.</h1>
                    <span></span>
                </div>

            </div>

        </div>
    </div>

    <img data-aos-delay="100" data-aos="fade-in" src={aboutServicesImg} alt="boy playing on phone"/>

</section>








{/*  End About Services Section */}



{/*  Start Our Team Section */}


<section className="our-team">
    <div className="container">
        <div className="our-team-content">
            <div className="header">
                <h1 data-aos-delay="100" data-aos="fade-in"> BOARD OF </h1>
                <h1 data-aos-delay="200" data-aos="fade-in"> DIRECTORS  </h1>
            </div>

            <div data-aos-delay="100" data-aos="fade-in" className="team-slider">
                <Swiper grabCursor={true}  centeredSlides={true} spaceBetween={30} slidesPerView={'auto'} navigation={{
                        nextEl: '.team-slider .next-btn',
                        prevEl: '.team-slider .prev-btn',
                        }} 
                            modules={[Navigation]}
                            >
                                

                                
                                
                    <SwiperSlide>
                    <div className="slide">
                        <div className="member">
                            <img src={Badr} alt="CEO Badr AlZidane"/>

                            <div className="info">
                                <h1> Badr Al Zidan </h1>
                                <h3> CEO (Chief Executive Officer) </h3>
                            </div>
                        </div>
                    </div>
                    </SwiperSlide>


                    <SwiperSlide>

                    <div className="slide">
                        <div className="member">
                            <img src={hmood} alt="CEO Badr AlZidane"/>

                            <div className="info">
                                <h1> Hamoud Al-Fayez </h1>
                                <h3> CBDO (Chief Business Development Officer) </h3>
                            </div>
                        </div>
                    </div>
                    </SwiperSlide>


                    <SwiperSlide>

                    <div className="slide">
                        <div className="member">
                            <img src={abdullah} alt="CEO Badr AlZidane"/>

                            <div className="info">
                                <h1> Abdullah Alsakaabi </h1>
                                <h3> Manager of Strategic Partnership </h3>
                            </div>
                        </div>
                    </div>
                    </SwiperSlide>

{/* 
                    <SwiperSlide>

                    <div className="slide">
                        <div className="member">
                            <img src={mohamedsaleh} alt="CEO Badr AlZidane"/>

                            <div className="info">
                                <h1> Mohammed Al-Saleh </h1>
                                <h3> Chairman  </h3>
                            </div>
                        </div>
                    </div>
                    </SwiperSlide>
 */}




                    </Swiper>


                <div className="buttons">
                    <button className="prev-btn"><i className="las la-angle-left"></i></button>
                    <button className="next-btn"><i className="las la-angle-right"></i></button>
                </div>
            </div>


            
        </div>
    </div>
</section>




{/*  */}


{/*  End Our Team Section */}




        </>
    )
}

export default AboutPage;   