
import CLOUDSTORAGEARCHIVINGVideo from '../showrealsservices/storage.mp4';
import videoImg from '../images/digitalassests/video.png';
import audioImg from '../images/digitalassests/audio.png';
import ImageImg from '../images/digitalassests/image.png';

function DigitalAssests(){
    return(
        <>
            <section className="digital-assests ar">
                <div className="container">
                    <div className="digital-assests-header">
                        <h1 data-aos-delay="200" data-aos="fade-in">إدارة الأصول الرقمية </h1>
                        <h2 data-aos-delay="300" data-aos="fade-in"> معاً سنحد من الهدر الرقمي عبر حفظ موادكم في محفظة رقمية خاصة  </h2>
                    </div>

                    <div data-aos-delay="400" data-aos="fade-in" className="three-d-model-video">
                        <video  src={CLOUDSTORAGEARCHIVINGVideo} playsInline autoPlay loop muted alt="project-video" > </video>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="digital-content">
                                <h1 data-aos-delay="100" data-aos="fade-in">
                                تتيح لكم خاصية "الأصول الرقمية" ، حفظ موادكم الخام ( فيديو ،صوت ،صور) عبر خوادم خاصة وسحابية آمنة ذات سعة تخزينية كبيرة.

                                </h1>
                                <span data-aos-delay="200" data-aos="fade-in">

                                </span>

                                <p data-aos-delay="300" data-aos="fade-in">
                                
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="digital-content">
                                <h1 data-aos-delay="100" data-aos="fade-in">
                                يمكنكم الاعتماد على هذه الخاصية للرجوع إلى موادكم وقت الحاجة إليها ، وتقليص كلفة إنتاج الأعمال المستقبلية مع "برو إفكت" . 
                                </h1>
                                

                                <div className="icons">
                                    <div data-aos-delay="100" data-aos="fade-in" className="icon">
                                        <img src={videoImg} alt="video" />
                                        <h3> فيديو </h3>
                                    </div>

                                    <div data-aos-delay="200" data-aos="fade-in" className="icon">
                                        <img src={audioImg} alt="audio" />
                                        <h3>  صوت </h3>
                                    </div>

                                    <div data-aos-delay="300" data-aos="fade-in" className="icon">
                                        <img src={ImageImg} alt="Image" />
                                        <h3> صورة </h3>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default DigitalAssests;