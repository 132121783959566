import React, { useEffect , useState, useRef  } from 'react';
import $ from 'jquery';

import { Link as RouterLink } from 'react-router-dom';
import TVCVideo from './showrealsservices/TVC 20 S V-4.mp4';
import VIDEOCLIPSVideo from './showrealsservices/Video Clip 30s V-4.mp4';
import DOCUMANTARIESVideo from './showrealsservices/Documentaries 30 S V-03.mp4';
import TELLYOURSTORYVideo from './showrealsservices/Tell your Story 30 S V-4.mp4';
import ANIMATIONVideo from './showrealsservices/20 S 2D - 3D Animation- V- 3.mp4';
import TVSERVICESVideo from './showrealsservices/TV Services V1.mp4';
import EVENTHIGHLIGHTSVideo from './showrealsservices/Event Highlights- 30 S V-3.mp4';
import BTSVideo from './showrealsservices/BTS 20 S V-3.mp4';
import AUDITORYVideo from './showrealsservices/auditory.mp4';
import CLOUDSTORAGEARCHIVINGVideo from './showrealsservices/storage.mp4';


function NewServices(){

    const videos = [
        {
            src: TVCVideo,
            title: 'TVC',
            description: 'We excel in crafting and presenting messages in a unique style that captures attention to achieve goals.',
            category: 'tvc'
        },
        {
            src: VIDEOCLIPSVideo,
            title: 'Video Clips',
            description: 'We translate your emotions into melodies, words, and shots that touch hearts and express your message in a captivating manner.',
            category: 'videoclips'
        },
        {
            src: DOCUMANTARIESVideo,
            title: 'Documentaries',
            description: 'We narrate your stories in a professional manner that immortalizes your achievements and inspires future generations.',
            category: 'documentaries'
        },
        {
            src: TELLYOURSTORYVideo,
            title: 'Tell Your Story',
            description: 'We transform your stories into impactful narratives that touch emotions and immortalize precious moments.',
            category: 'storytelling'
        },
        {
            src: ANIMATIONVideo,
            title: '2D & 3D Animation',
            description: 'We bring your visions to life with captivating 2D & 3D animations, crafting immersive experiences that enchant the imagination and bring stories to vibrant reality.',
            category: 'animation'
        },
        {
            src: TVSERVICESVideo,
            title: 'TV Services',
            description: 'We provide comprehensive TV services, delivering high-quality broadcasting solutions.',
            category: 'tv'
        },
        {
            src: EVENTHIGHLIGHTSVideo,
            title: 'Event Highlights',
            description: 'We transform your events into unforgettable experiences and document them with precision and creativity to live on in memory.',
            category: 'events'
        },
        {
            src: BTSVideo,
            title: 'BTS',
            description: 'We capture the essence of Behind The Scenes (BTS), offering a unique glimpse into the creative process.',
            category: 'bts'
        },
        {
            src: AUDITORYVideo,
            title: 'Designing Auditory Identities',
            description: 'We design a distinctive auditory identity that enhances your brand and sets you apart from competitors.',
            category: 'audio'
        },
        {
            src: CLOUDSTORAGEARCHIVINGVideo,
            title: 'Cloud Storage Archiving',
            description: 'Sustainable storage in the cloud world preserves your dreams and projects in one place.',
            category: 'storage'
        }
    ];
    const videoRefs = useRef(videos.map(() => React.createRef()));
    const [playing, setPlaying] = useState({});
    const [userInteracted, setUserInteracted] = useState({});
    
    const handleMouseEnter = (index) => {
        if (videoRefs.current[index].current && !playing[index] && userInteracted[index]) {
            videoRefs.current[index].current.play();
            setPlaying(prev => ({ ...prev, [index]: true }));
        }
    };
    
    const handleMouseLeave = (index) => {
        if (videoRefs.current[index].current && playing[index]) {
            videoRefs.current[index].current.pause();
            setPlaying(prev => ({ ...prev, [index]: false }));
        }
    };
    
    const handleClick = (index) => {
        if (videoRefs.current[index].current) {
            setUserInteracted(prev => ({ ...prev, [index]: true }));
            if (playing[index]) {
                videoRefs.current[index].current.pause();
            } else {
                videoRefs.current[index].current.play();
            }
            setPlaying(prev => ({ ...prev, [index]: !prev[index] }));
        }
    };

    return(
        <>
            <div id="services" className="new-our-services">
                <div className='container'>
                    <div className="our-services-content">
                        <div className="header">
                            <h1 data-aos-delay="100" data-aos="fade-in">OUR </h1>
                            <h1 data-aos-delay="200" data-aos="fade-in">SERVICES</h1>
                        </div>

                        <div className='row'>

                        {videos.map((video, index) => (
                            <div key={index} className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='service'>
                                <video
                                        ref={videoRefs.current[index]}
                                        src={video.src}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={() => handleMouseLeave(index)}
                                        onClick={() => handleClick(index)}
                                        loop
                                        
                                        controls={false}
                                    ></video>
                                    {!playing[index] && (
                                        <button className='play-video' onClick={() => handleClick(index)}>
                                            <i className="las la-play"></i>
                                        </button>
                                    )}
                                    <div className='description'>
                                        <h1>{video.title}</h1>
                                        <p>{video.description}</p>
                                        <div>
                                            <RouterLink to={`/work?category=${video.category}`} aria-label="view project">
                                                SEE PROJECTS
                                            </RouterLink>
                                            <RouterLink to={`/work?category=${video.category}`} aria-label="view-all-projects">
                                                <i className="las la-plus"></i>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}


                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}


export default NewServices;