

import OverlayImage from "./images/Rectangle -1.webp";
import Oletter from "./images/objects/goals.png";

import AdvImg from "./images/advsearv/1.webp";


function AdvancedServices(){
    return(
        <>
            <section className="advanced-services">
                <div className="container">
                    <div className="advanced-header">
                        <img className="overlay" src={OverlayImage} alt="overlay"/>
                        <div>
                            <p ata-aos-delay="100" data-aos="fade-in"> <strong>We strive</strong>  to deliver unique and immersive experiences that exceed our clients' expectations and elevate creative content to new levels of innovation and impact. </p>
                            <span ata-aos-delay="200" data-aos="fade-in"> </span>
                        </div>

                        {/* <h1 ata-aos-delay="300" data-aos="fade-in"> PARTNERS </h1> */}

                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="adv-ser-content">
                                <div className="adv-serv">
                                    <h1 ata-aos-delay="100" data-aos="fade-in"> BLUERays Holdings: </h1>
                                    <p ata-aos-delay="200" data-aos="fade-in">
                                    BLUERays Holdings is a dynamic holding company in Riyadh, founded by proficient experts from various fields. The idea flourished alongside the growth of opportunities and the economic transformation initiated by the Kingdom of Saudi Arabia, as part of its ambitious Vision 2030, led by its zealous youth. Leveraging our cumulative experience at BLUERays, we pave the way for leadership opportunities through our distinguished services, aiming to build a bright future for your project.
                                    </p>

                                    <ul>
                                        <li ata-aos-delay="100" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            Entrepreneurial project establishment
                                        </li>

                                        <li ata-aos-delay="200" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            Brand creation
                                        </li>

                                        <li ata-aos-delay="300" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            Consultations and business development
                                        </li>

                                        <li ata-aos-delay="400" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            Strategic planning
                                        </li>

                                        <li ata-aos-delay="500" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            Market research studies
                                        </li>
                                    </ul>
                                </div>

                                <div className="adv-serv">
                                    <h1 ata-aos-delay="100" data-aos="fade-in"> SHAGHAF: </h1>
                                    <p ata-aos-delay="200" data-aos="fade-in">
                                    SHAGHAF is a specialized Saudi marketing group, dedicated to providing consultancy and marketing services, as well as designing and implementing communication campaigns that embody a local spirit and authentic culture aligned with the ambitious directions of Saudi Vision 2030. Our outputs are delivered through innovative and creative methods that inspire the senses and achieve the desired impact for our clients and partners in success
                                    </p>

                                    <ul>
                                        <li ata-aos-delay="100" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            Communication strategies
                                        </li>

                                        <li ata-aos-delay="200" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            Digital marketing
                                        </li>

                                        <li ata-aos-delay="300" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            Creative content writing and creation
                                        </li>

                                        <li ata-aos-delay="400" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            Branding identity and design 
                                        </li>

                                        <li ata-aos-delay="500" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            Social media account management
                                        </li>


                                        <li ata-aos-delay="600" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            Market research and digital data analysis
                                        </li>

                                        <li ata-aos-delay="700" data-aos="fade-in">
                                            <img src={Oletter} alt="O letter"/>
                                            Consultancy services
                                        </li>
                                    </ul>
                                </div>


                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="adv-serv-img">
                                <img ata-aos-delay="200" data-aos="fade-in" src={AdvImg} alt="advanced search content" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AdvancedServices;